@import "colors";

.home-delivery-status {
  &.home-delivery-status--in-stock,
  &.home-delivery-status--in-stock-no-purchase {
    color: $green;
  }
}

.pickup-possible {
  color: $green;
}

table.stock {
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 0.7rem;

  td {
    float: none;
    padding: 2px 2px 2px 0;
    border-bottom: 1px solid #ddd;
    vertical-align: middle;
  }

  td.label {
    font-weight: bold;
  }

  td.stock {
  }

  td span {
    display: inline-block;
    color: #888;
  }

  td.info {
    padding: 0;

    i {
      padding: 6px 2px 6px 6px;
      font-size: .8rem;
    }
  }

  tr.shop-pickup-status {
    &.shop-pickup-status--in-stock {
      td {
        color: $green;
      }
    }

    &.shop-pickup-status--in-central-stock {

    }

    &.shop-pickup-status--in-stock-no-purchase {
      td {
        color: $orange;
      }
    }

    &.shop-pickup-status--in-stock-at-supplier, &.shop-pickup-status--backorder {
      td {
        color: $orange;
      }
    }

    &.shop-pickup-status--not-available {
      td {
        color: $red;
      }
    }
  }
}


.content {
  h1 {
    color: #760098;
    font-weight: 600;
    font-size: 2rem;
    line-height: 2rem;
    margin: 0 0 25px 0;
  }

  h1.center {
    text-align: center;
  }

  h1.left {
    float: left;
  }

  h1.hr {
    color: #3e1a5b;
    font-size: 1.4rem;
    line-height: 1.4rem;
    background: url('../_graphics/pixel_5c007d.png') center left repeat-x;
    text-transform: uppercase;
    text-align: center;
    margin: 0 0 35px 0;
  }

  h1.hr strong{
    display: inline-block;
    position: relative;
    background: #fff;
    padding: 0 30px;
  }

  h1.hr strong:before {
    content: "";
    position: absolute;
    left: 18px;
    top: 5px;
    z-index: 100;
    background: #ba80cb;
    width: 1px;
    height: 15px;
  }

  h1.hr strong:after {
    content: "";
    position: absolute;
    right: 18px;
    top: 5px;
    z-index: 100;
    background: #ba80cb;
    width: 1px;
    height: 15px;
  }

  h2 {
    color: #3e1a5b;
    font-size: 1.4rem;
    line-height: 1.4rem;
    background: url('../_graphics/pixel_5c007d.png') center left repeat-x;
    text-transform: uppercase;
    text-align: center;
    margin: 0 0 35px 0;

    &.faq-category, {
      margin: 2rem 0 1rem 0;
      line-height: 1rem;
      color: #760098;
      background: none;
      font-weight: bold;
      text-align: left;
      scroll-margin-top: 120px;
    }

    &.product {
      margin: 0 0 0.5rem 0;
      font-size: 1rem;
      line-height: 1rem;
      color: #760098;
      background: none;
      font-weight: bold;
      text-align: left;
      text-transform: none;
      text-decoration: none;
    }
  }

  h2 strong {
    display: inline-block;
    position: relative;
    background: #fff;
    padding: 0 30px;
  }

  h2 strong:before {
    content: "";
    position: absolute;
    left: 18px;
    top: 5px;
    z-index: 100;
    background: #ba80cb;
    width: 1px;
    height: 15px;
  }

  h2 strong:after {
    content: "";
    position: absolute;
    right: 18px;
    top: 5px;
    z-index: 100;
    background: #ba80cb;
    width: 1px;
    height: 15px;
  }

  h2.left {
    text-align: left;
  }

  h2.left strong {
    padding-left: 0;
    padding-right: 15px;
  }

  h2.left strong:before,
  h2.left strong:after {
    display: none;
  }

  h2.smaller {
    font-size: 1.1rem;
    line-height: 1.1rem;
  }
}

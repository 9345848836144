.spotlights {
  float: left;
  width: 100%;
  margin-bottom: 25px;
  display: flex;
  flex-wrap: wrap;
}

a.spotlight {
  float: left;
  width: 20%;
  margin-bottom: 15px;
  padding: 15px 1%;
  text-decoration: none;
  box-sizing: border-box;
  border: 1px solid #fff;

  div.image {
    float: left;
    width: 100%;
    position: relative;
    margin-bottom: 20px;

    img {
      float: left;
      width: 100%;
      opacity: 0;
    }

    img.active {
      opacity: 1;
    }

    div {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      background-size: contain !important;
    }
  }

  div.info {
    float: left;
    width: 100%;
    text-align: center;
  }

  div.info b {
    float: left;
    width: 100%;
    margin-bottom: 5px;
    font-weight: 700;
    font-size: 0.8rem;
    line-height: 1rem;
    text-transform: uppercase;
    text-decoration: none;
  }

  div.info span.price {
    display: inline-block;
    color: #ffe420;
    background: #5c007d;
    font-size: 0.8rem;
    line-height: 1rem;
    font-weight: 600;
    padding: 5px 8px;
    padding-left: 4px;
    position: relative;
  }

  div.info span.price:before {
    content: "";
    position: absolute;
    top: 0;
    left: -10px;
    width: 10px;
    height: 100%;
    background: url('../_graphics/bg_price.png') center left no-repeat;
    background-size: 50px auto;
  }

  &:hover {
    border: 1px solid #ddd;
  }
}



.spotlights a.spotlight:nth-child(6) {
  display: none;
}
/* product banners */

a.product, div.product, a.spotlight {
  position: relative;

  div.image {
    strong {
      position: absolute;
      top: 15px;
      left: 0;
      z-index: 10;
      color: #fff;
      font-size: 0.8rem;
      font-weight: 400;
      line-height: 1rem;
      padding: 6px 8px;
    }

    strong.second {
      top: 47px;
    }

    strong:before{
      content: "";
      position: absolute;
      top: 0;
      left: -13px;
      width: 12px;
      height: 100%;
      background-size: 12px 22px !important;
    }

    strong:after {
      content: "";
      position: absolute;
      top: 0;
      right: -13px;
      width: 12px;
      height: 100%;
      background-size: 12px 22px !important;
    }

    /* purple banner */

    strong.purple{
      background: #760098;
    }

    strong.purple:before {
      background: url('../_graphics/flag_purple_left.png') center left no-repeat;
    }

    strong.purple:after {
      background: url('../_graphics/flag_purple_right.png') center right no-repeat;
    }

    /* orange banner */

    strong.orange {
      background: #f5a623;
    }

    strong.orange:before {
      background: url('../_graphics/flag_orange_left.png') center left no-repeat;
    }

    strong.orange:after {
      background: url('../_graphics/flag_orange_right.png') center right no-repeat;
    }

    /* blue banner */

    strong.blue {
      background: #4a90e2;
    }

    strong.blue:before {
      background: url('../_graphics/flag_blue_left.png') center left no-repeat;
    }

    strong.blue:after {
      background: url('../_graphics/flag_blue_right.png') center right no-repeat;
    }

    /* green banner */

    strong.green {
      background: #9bc53d;
    }

    strong.green:before {
      background: url('../_graphics/flag_green_left.png') center left no-repeat;
    }

    strong.green:after {
      background: url('../_graphics/flag_green_right.png') center right no-repeat;
    }

    /* red banner */

    strong.red {
      background: #ff3c15;
    }

    strong.red:before {
      background: url('../_graphics/flag_red_left.png') center left no-repeat;
    }

    strong.red:after {
      background: url('../_graphics/flag_red_right.png') center right no-repeat;
    }
  }
}

@import "breakpoints";

.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 500;
  width: 100%;
  height: 170px;
  border-bottom: 1px solid #5c007d;
  transition: all 0.4s;
}

.header .top {
  float: left;
  width: 100%;
  height: 40px;
  background: #760098;
  transition: all 0.4s;
}

.header .middle {
  float: left;
  width: 100%;
  height: 70px;
  background: #3e1a5b;
  transition: all 0.4s;
}

.header .bottom {
  float: left;
  width: 100%;
  height: 60px;
  background: #fff;
}

/* header scroll */

.header.scroll {
  height: 100px;
}

.header.scroll .middle {
  height: 0;
}

/* logo */

.logo {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  height: 110px;
  transition: all 0.4s;
}

.header.scroll .logo {
  height: 40px;
}

/* general nav */

.header .top nav {
  float: left;
  margin-top: 13px;
  margin-left: 280px;
  transition: all 0.4s;
}

.header.scroll .top nav {
  margin-top: -50px;
}

.header .top nav a {
  float: left;
  color: #e1d0f0;
  font-size: 0.9em;
  line-height: 1em;
  text-decoration: none;
  margin-right: 20px;
  border-bottom: 0;
  position: relative;
}

.header .top nav a:after {
  content: "";
  position: absolute;
  right: -10px;
  top: 0;
  z-index: 100;
  background: #ba80cb;
  width: 1px;
  height: 12px;
}

.header .top nav a:last-child {
  margin-right: 0;
}

.header .top nav a:last-child:after {
  display: none;
}

.header .top nav a:hover {
  color: #fff;
  border-bottom: 1px solid #fff;
}

.header .top nav a.active {
  color: #ffe420;
  border: 0 !important;
}

/* cart link */

.header .top a.cart,
.header .top a.offer{
  float: right;
  color: #3e1a5b;
  background: #ffe420;
  height: 16px;
  padding: 12px 15px;
  margin-left: 3px;
  font-size: 1em;
  line-height: 1em;
  font-weight: 400;
  text-transform: uppercase;
  text-decoration: none;
  transition: all 0.4s;
  position: relative;
}

.header .top a.cart i,
.header .top a.offer i{
  display: none;
}

.header .top a.cart b,
.header .top a.offer b{
  display: inline-block;
  font-weight: 800;
  margin-left: 10px;
  padding-left: 10px;
  border-left: 1px solid #ddaa3d;
}

.header .top a.cart:hover,
.header .top a.offer:hover {
  background: #eace26;
}

.header.scroll .top a.cart {
  margin-right: 218px;
}

.header.scroll .top a.cart.offer {
  margin-right: 0;
  margin-left: 218px;
}
/* cart link hover block */

.header .top a.cart div {
  position: absolute;
  top: 40px;
  right: 0;
  z-index: 900;
  width: 350px;
  padding: 15px;
  background: #fff;
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.15);
  display: none;
}

.header .top a.cart div span.info {
  color: #888;
  font-size: 0.85rem;
  line-height: 2rem;
  text-transform: none;
}
.header .top a.cart div span.info strong {
  color: $purple-dark;
  text-transform: uppercase;
}

.header .top a.cart div .close-cart {
  float: right;
  display: block;
  width: 20px;
  height: 20px;
  text-indent: -9999px;
  background-size: 10px 10px;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url('../_graphics/close.png');
  border: 2px solid transparent;
}

.header .top a.cart div .close-cart:hover {
  border-color: lightgrey;
}

.header .top a.cart div table.cart td {
  text-transform: none;
  border-top: 1px solid #ddd;
}

.header .top a.cart div table.cart td {
  text-transform: none;
  border-top: 1px solid #ddd;
}

.header .top a.cart div table.cart td p {
  color: #888;
  font-size: 0.85rem;
  line-height: 1rem;
}

.header .top a.cart div table.cart td.product strong {
  font-size: 0.85rem;
  line-height: 1rem;
}

.header .top a.cart div table.cart td.thumb {
  width: 60px;
}

.header .top a.cart div table.cart td.thumb img {
  float: left;
  width: 40px;
  margin: 0 10px;
}

.header .top a.cart div table.cart td b {
  font-size: 0.9rem;
  font-weight: 700;
  border-left: 0;
}

.header .top a.cart div table.cart td.total {
  font-size: 1rem;
}

.header .top a.cart div span.button {
  float: left;
  width: 100%;
  padding: 10px 0;
  margin-top: 5px;
  font-size: 0.9rem;
  text-align: center;
  color: #5c007d;
  background: #ffe420;
}

.header .top a.cart div span.button:hover {
  color: #fff;
  background: #760098;
}

.cart-container{
  background: #ffe420;
  margin: 0;
  padding: 15px;
  color: #5c007d;
  font-size: 25px;
}
.cart-container:hover{
  color: #ffe420;
}

.delivery-country-link img {
  margin-left: .5em;
  height: 0.8rem;
  vertical-align: middle;
}

/* lan switch */

ul.lan_switch {
  float: right;
  margin-top: 13px;
  list-style-type: none;
  text-align: right;
  margin-right: 15px;

  @media all and (max-width: $tablet) {
    float: left;
    margin-top: 18px;
    margin-left: 10px;
  }

  li {
    float: left;
  }

  li a {
    float: left;
    color: #e1d0f0;
    font-size: 0.9em;
    line-height: 1em;
    text-decoration: none;
    margin-right: 20px;
    border-bottom: 0;
    position: relative;
  }

  li a:after {
    content: "";
    position: absolute;
    right: -10px;
    top: 0;
    z-index: 100;
    background: #ba80cb;
    width: 1px;
    height: 12px;
  }

  li:last-child a {
    margin-right: 0;
  }

  li:last-child a:after {
    display: none;
  }

  li a:hover {
    color: #fff;
    border-bottom: 1px solid #fff;
  }

  li a.active,
  li a.a:hover {
    color: #ffe420;
    border: 0 !important;
  }
}

/* nav toggle */

.header {
  .mobile_icons {
    display: none;
    float: right;
    margin-right: .5rem;

    @media all and (max-width: $tablet) {
      display: flex;
      height: 50px;
      gap: 1rem;
      align-items: center;
    }

    @media all and (max-width: $phone) {
      gap: .5rem;
    }

    .nav_toggle {
      display: none;

      @media all and (max-width: $tablet) {
        display: block;
      }

      img {
        height: 20px;
      }
    }

    i.shops-locations-icon {
      color: #ffe420;
      text-decoration: none;
      font-size: 24px;
    }

    div.delivery-country-mobile {
      span {
        display: none;
      }

      img {
        height: 1rem;
        margin-left: 0;
      }
    }
  }
}
.header__login-button {
  float: right;
  color: #3e1a5b;
  background: #ffe420;
  height: 16px;
  padding: 12px 15px;
  font-size: 1em;
  line-height: 1em;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  transition: all .4s;
  position: relative;
  .login-button__icon {
    display: none;
  }
  @media all and (max-width: $tablet) {
    padding: 17px 15px;
    margin-left: 0;
    margin-right: 0 !important;
    border-right: 1px solid #3e1a5b;
  }
  @media all and (max-width: 60em) {
    .login-button__text {
      display: none;
    }
    .login-button__icon {
      display: inline-block;
    }
  }
  &:hover {
    background: #eace26;
  }
}

span.energy_tag {
  display: inline-block;
  min-width: 15px;
  width: auto;
  padding: 3px 3px 3px 4px;
  height: 10px;
  background-color: #979797;
  border-color: #979797;
  position: relative;
  text-align: center;
  font-size: 0.7rem;
  line-height: 0.6rem;
  font-weight: 600;
}

span.energy_tag:after {
  right: -8px;
  top: 0;
  content: "";
  position: absolute;
  display: block;
  border-left: 8px solid white;
  border-color: inherit;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
}

span.energy_tag_big {
  display: inline-block;
  min-width: 30px;
  width: auto;
  padding: 7px 3px 7px 4px;
  height: 18px;
  background-color: #979797;
  border-color: #979797;
  position: relative;
  text-align: center;
  font-size: 1.1rem;
  line-height: 1.1rem;
  font-weight: 550;
}

span.energy_tag_big:after {
  right: -16px;
  top: 0;
  content: "";
  position: absolute;
  display: block;
  border-left: 16px solid white;
  border-color: inherit;
  border-top: 16px solid transparent;
  border-bottom: 16px solid transparent;
}

/*span.energy {
	display: inline-block;
	color: #fff;
	font-size: 0.7rem;
	line-height: 0.7rem;
	font-weight: 600;
	padding: 3px 1px 3px 5px;
	padding-left: 4px;
	margin-left: 4px;
	position: relative;
}

span.energy.big {
	font-size: 1.2rem;
	line-height: 1.2rem;
	font-weight: 600;
	padding: 7px 8px;
	padding-left: 4px;
	margin-left: 15px;
}

span.energy:after {
    content: "";
    position: absolute;
    top: 0;
    right: -8px;
	width: 8px;
	height: 100%;
	background-size: 50px auto;
}

span.energy.big:after {
	right: -14px;
	width: 14px;
	background-size: 53px auto;
}

span.energy.a_plus_plus_plus {
	background: #00a755;
}

span.energy.a_plus_plus_plus:after {
	background: url('../_graphics/bg_a_plus_plus_plus.png') center right no-repeat;
}

span.energy.a_plus_plus {
	background: #46b94b;
}

span.energy.a_plus_plus:after {
	background: url('../_graphics/bg_a_plus_plus.png') center right no-repeat;
}

span.energy.a_plus {
	background: #bfd839;
}

span.energy.a_plus:after {
	background: url('../_graphics/bg_a_plus.png') center right no-repeat;
}

span.energy.a {
	background: #fff528;
}

span.energy.a:after {
	background: url('../_graphics/bg_a.png') center right no-repeat;
}

span.energy.b {
	background: #ffb823;
}

span.energy.b:after {
	background: url('../_graphics/bg_b.png') center right no-repeat;
}

span.energy.c {
	background: #f67024;
}

span.energy.c:after {
	background: url('../_graphics/bg_c.png') center right no-repeat;
}

span.energy.d {
	background: #f10822;
}

span.energy.d:after {
	background: url('../_graphics/bg_d.png') center right no-repeat;
}*/

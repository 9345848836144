@import "lightslider/src/css/lightslider";
@import "lightSlider";
@import "lightgallery/scss/lightgallery";
@import "lightgallery/scss/lg-zoom";
@import "lightgallery/scss/lg-video";
@import "lightgallery/scss/lg-thumbnail";

@import "colors";
@import "sectioned-pages";
@import "account-page";

/*

LOETERS STYLES

font-family: 'Open Sans', sans-serif;
font-weight: 400/600/700/800;
font-style: italic;

YELLOW: #ffe420

PURPLE (DARK): #3e1a5b
PURPLE (MIDDLE): #5c007d 
PURPLE (LIGHT): #760098
PURPLE (VERY LIGHT): #e1d0f0

GREY: #4a4a4a
GREY (LIGHT): #888

RED: #ff3c15
GREEN: #1E7200
BLUE: #4a90e2
ORANGE: #f5a623

*/



/* general */

* { 
	border: 0;
	padding: 0;
	margin: 0; 
}

html {
	font-size: 16px;
}

body {
	background: #fff;
	color: #666;
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
}

br.clear {
	clear: both;
}

.preload * {
	-webkit-transition: none !important;
	-moz-transition: none !important;
	-ms-transition: none !important;
	-o-transition: none !important;
}

@import "tooltip";

/* wrapper */

.wrapper {
	margin: 0 auto;
	max-width: 1200px;
	position: relative;
}

/* header */

@import "header";


/* tagline */

em.tagline {
	float: left;
	font-weight: 600;
	font-size: 1.2em;
	line-height: 1em;
	color: #ffe420;
	margin-top: 25px;
	margin-left: 280px;
	transition: all 0.4s;
}

.header.scroll em.tagline {
	margin-top: -10px;
	opacity: 0;
}

/* search form */

.search_form {
	float: right;
	margin-top: 15px;
	transition: all 0.4s;
}

.header.scroll .search_form {
	margin-top: -40px;
	margin-right: 0 !important;
}

.search_form input.text {
	float: right;
	width: 200px;
	height: 20px;
	background: #fff;
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
	font-size: 0.9em;	
	line-height: 1em;
	color: #4d4d4d;
	padding: 10px 14px;
	outline: none;
	-webkit-appearance: none;
	-webkit-border-radius:0; 
    border-radius:0;
	transition: all 0.3s;
}

.header.scroll .search_form input.text {
	height: 16px;
	width: 166px;
	padding: 9px 5px 9px 10px;
	margin-top: 3px;
}

.search_form button {
	float: right;
	width: 40px;
	height: 40px;
	border: 0;
	margin: 0;
	padding: 0;
	border-radius: 0;
	cursor: pointer;
	overflow: visible;
	outline: none;
	color: #3e1a5b;
	background: #ffe420;
	text-decoration: none;
	text-transform: uppercase;
	position: relative;
	transition: all 0.3s;
}

.search_form button:after {
	position: absolute;
	top: 13px;
	left: 0;
	width: 100%;
	text-align: center;
	z-index: 100;
    content: "\f002";
    font-family: "FontAwesome";
    font-size: 15px;
    color: #3e1a5b;
    position: absolute;
    pointer-events: none;
    transition: all 0.3s;
}

.search_form button:hover {
	background: #eace26;
}

.header.scroll .search_form button {
	margin-top: 3px;
	height: 34px;
	width: 34px;
}

.header.scroll .search_form button:after {
	top: 10px;
}

/* nav */

ul.nav {
	float: left;
	width: 100%;
	text-decoration: none;
	list-style-type: none;
	overflow: hidden;
	white-space: nowrap;
}

ul.nav li {
	display: inline-block;
	vertical-align: top;
}

ul.nav li a {
	float: left;
	color: #3e1a5b;
	text-decoration: none;
	text-transform: uppercase;
	margin-right: 20px;
	font-size: 1.25em;
	font-weight: 800;
	line-height: 1em;
	height: 20px;
	padding-top: 22px;
	padding-bottom: 13px;
	border-bottom: 5px solid #fff;
}

ul.nav li:hover a {
	color: #3e1a5b;
	border-bottom: 5px solid #3e1a5b;
}

ul.nav li.active a {
	color: #5c007d;
	border-bottom: 5px solid #5c007d;
}

/* subnav rollover */

ul.nav li div.subnav {
	position: absolute;
	left: 0;
	top: 60px;
	z-index: 500;
	width: 100%;
	padding-top: 40px;
	background: #fff;
	box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border: 1px solid #5c007d;
	transition: top 0.2s ease-in-out;
	display: none;
}

ul.nav li div.subnav_wrapper {
	display: flex;
	flex-wrap: wrap;
}

ul.nav li div.subnav_block {
	display: flex;
	width: 25%;
	box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border-right: 1px solid #e1d0f0;
	margin-bottom: 40px;
}

ul.nav li div.subnav_wrapper div.subnav_block:nth-child(4n) {
	border: 0;	
}

ul.nav li div.subnav_block .img-wrapper {
	float: left;
	width: 30%;
	text-align: center;
}

ul.nav li div.subnav_block .img-wrapper img {
	max-height: 110px;
	align-self: flex-start;
	max-width: 90%;
}

ul.nav li div.subnav_block div.right {
	float: right;
	width: 67%;
	margin-right: 3%;
}

ul.nav li div.subnav_block a {
	float: left;
	width: 100%;
	height: auto;
	font-size: 0.9em;
	line-height: 1.3em;
	font-weight: 400;
	color: #888;
	margin: 0;
	padding: 0;
	border: 0;
	margin-bottom: 6px;
	text-transform: none;
	text-decoration: underline;
	white-space: normal;
}

ul.nav li div.subnav_block a:hover {
	color: #760098;
}

ul.nav li div.subnav_block a.active {
	text-decoration: none;
}

ul.nav li div.subnav_block a.active span {
	padding: 1px 4px;
	color: #5c007d;
	background: #ffe420;
}

ul.nav li div.subnav_block a.parent {
	color: #3e1a5b;
	font-size: 1.1em;
	line-height: 1.1em;
	font-weight: 700;
	margin-bottom: 15px;
	text-decoration: none;
	text-transform: uppercase;
}

ul.nav li div.subnav_block a.parent span {
	display: inline;
	white-space: normal;
}

ul.nav li div.subnav_block a.parent:hover {
	color: #760098;
}

ul.nav li div.subnav_block a.parent:hover span {
	text-decoration: underline;
}


/* mobile nav */

.mobile_nav {
	float: left;
	width: 100%;
	background: #3e1a5b;
	display: none;
}

.mobile_nav a {
	float: left;
	width: 50%;
	padding: 15px 0;
	color: #fff;
	text-decoration: none;
	text-transform: uppercase;
	font-size: 1.25em;
	font-weight: 800;
	line-height: 1em;
	border-bottom: 1px solid #760098;
}

.mobile_nav a span {
	padding: 0 10px;
}

/* container */

.container {
	float: left;
	width: 100%;
	padding-top: 170px;
}

/* content */

.content {
	float: left;
	width: 100%;
	padding-top: 30px;
}

/* links */	
	
.content a {
	color: #760098;
}

.content a.link {
	cursor: pointer;
}

.content a:hover {
	color: #3e1a5b;
}

.content a.red {
	color: #ff3c15;
}

.content a.red:hover {
	color: #4d4d4d;
}

.content a.grey {
	color: #888;
}

.content a.grey:hover {
	color: #4d4d4d;
}

/* buttons */

.content a.button {
	display: inline-block;
	color: #fff;
	background: #5c007d;
	text-decoration: none;
	text-transform: uppercase;
	font-weight: 600;
	font-size: 0.9rem;
	line-height: 0.9rem;
	padding: 10px 20px;
	border-radius: 3px;
	margin-right: 10px;
	margin-bottom: 10px;
}

.content a.button:hover {
	background: #760087;
}

.content a.button.yellow {
	color: #760098;
	background: #ffe420;
}

.content a.button.yellow:hover {
	color: #fff;
	background: #760098;
}

.content a.button.right {
	float: right;
}

.content a.button.icon_clock {
	position: relative;
	padding-left: 40px;	
}

.content a.button.icon_clock:after {
 	content: "\f017";
    font-family: "FontAwesome";
    font-size: 18px;
    font-weight: 400;
    color: #fff;
    left: 15px;
    top: 10px;
    position: absolute;
}

.content a.button.yellow.icon_clock:after {
    color: #760098;
}

.content a.button.yellow.icon_clock:hover:after {
    color: #fff;
}

.content a.button.icon_contact {
	position: relative;
	padding-left: 40px;	
}

.content a.button.icon_contact:after {
 	content: "\f003";
    font-family: "FontAwesome";
    font-size: 18px;
    font-weight: 400;
    color: #fff;
    left: 15px;
    top: 10px;
    position: absolute;
}

.content a.button.yellow.icon_contact:after {
    color: #760098;
}

.content a.button.yellow.icon_contact:hover:after {
    color: #fff;
}

.content a.button.icon_list {
	position: relative;
	padding-left: 40px;	
}

.content a.button.icon_list:after {
 	content: "\f0c9";
    font-family: "FontAwesome";
    font-size: 18px;
    font-weight: 400;
    color: #fff;
    left: 15px;
    top: 10px;
    position: absolute;
}

.content a.button.yellow.icon_list:after {
    color: #760098;
}

.content a.button.yellow.icon_list:hover:after {
    color: #fff;
}

/* titles */

@import "titles";

/* other */

.content p {
	line-height: 1.6em;
	margin-bottom: 25px;
}

.content p.center {
	text-align: center;
}

.content p.nomargin {
	margin-bottom: 5px;
}

.content ul {
	list-style-type: disc;
	margin-bottom: 25px;
}

.content ol {
	list-style-type: decimal;
	margin-left: 15px;
	margin-bottom: 25px;
	padding-left: 15px;	
}

.content li {
	margin-left: 25px;
	margin-bottom: 5px;
	line-height: 1.4em;
}

.content ul.check {
	list-style-type: none;
	margin-bottom: 25px;
}

.content ul.check li {
	margin-left: 0;
	padding-left: 26px;
	margin-bottom: 8px;
	position: relative;
}

.content ul.check li:after {
    content: "\f005";
    font-family: "FontAwesome";
    font-size: 16px;
    color: #009fd8;
    left: 0;
    top: 0;
    position: absolute;
    pointer-events: none;
}

/*

.content ul.list {
	float: left;
	width: 100%;
	list-style-type: none;
	margin-bottom: 30px;
}

.content ul.list li {
	float: left;
	width: 100%;
	padding: 10px 0;
	margin: 0;
	line-height: 1.3em;
	border-bottom: 1px solid #ddd;
}

.content ul.list li a.selected {
	color: #009fd8;
	text-decoration: none;
	border: 0;
}

.content ul.list li.search:hover {
	background: #ecf6fc;
}

.content ol.list {
	margin-left: 0;
}

.content ol.list li {
	padding: 2px 0;
}

*/

.content table {
	width: 100%;
	margin-bottom: 25px;
}

.content td {
	padding: 5px;
	vertical-align: top;
	line-height: 1.4em;
	border-bottom: 1px solid #ddd;
}

.content hr {
	float: left;
	width: 100%;
	border-bottom: 1px solid #ddd;
	margin-bottom: 25px;
}

.content hr.spacer10 {
	border: 0;
	margin-bottom: 10px;
}

.content hr.spacer15 {
	border: 0;
	margin-bottom: 15px;
}

.content hr.spacer25 {
	border: 0;
}

.content hr.spacer50 {
	border: 0;
	margin-bottom: 50px;
}

.content hr.yellow {
	border-bottom: 4px solid #ffe420;
	margin-bottom: 50px;
}

.responsive_iframe_wrapper {
    float: none;
    clear: both;
    width: 100%;
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0;
}

.responsive_iframe_wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* text */

.text {
	float: left;
	width: 100%;	
}

/* sidebar */

.sidebar {
	float: left;
	width: 40%;
}

.sidebar.right {
	float: right;
}

/* centered text */

.centered_text {
	float: left;
	width: 75%;
	margin: 0 12.5%;
}

/* alert */

span.alert {
	float: left;
	width: 100%;
	color: #3e1a5b;
	background: #ffe420;
	padding: 12px 15px;
	box-sizing: border-box;
	border-radius: 3px;
	font-size: 15px;
	font-weight: 600;
	text-align: center;
	margin-bottom: 20px;
}

/* google maps */

#google_maps_canvas {
	float: left;
	width: 100%;
	margin-bottom: 25px;
}

#google_maps_canvas.home {
	width: 55%;
	height: 350px;
}

/* text banner */

img.text_banner {
	float: left;
	width: 55%;
	margin-bottom: 25px;
}

img.text_banner.right {
	float: right;
}

/* usps */

.usps {
	float: left;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	gap: 0 4em;
	width: 100%;
	margin-bottom: 30px;
}

.usps span {
	margin-bottom: 10px;
	font-weight: 400;
	font-size: 1em;
	color: #888;
}

.usps span i {
	font-size: 1.1em;
	margin-right: 6px;
	color: #9bc53d;
}

.usps span br {
	display: none;
}

/* banners */

.banners {
	float: left;
	width: 100%;
}

.banners a {
	float: left;
	width: 32%;
	margin-right: 1%;
	margin-left: 0;
	margin-bottom: 25px;
	text-decoration: none;
	position: relative;
	overflow: hidden;
}

.banners a.static {
	width: 32.6%;
	margin-right: 1%;
}

.banners a:nth-child(3n) {
	margin-right: 0;
}

.banner_text {
	float: left;
	width: 90.5%;
	padding: 20px 5% 25px 5%;
	margin-top: 160px;
	color: #fff;
	font-size: 1.4em;
	line-height: 1.6em;
	background: rgba(55, 55, 55, 0.7);
	text-align: center;
	position: relative;
	z-index: 2;
	transition: all 0.3s;
	min-height: 70px;
}

.banner_text strong {
	float: left;
	width: 100%;
	color: #ffe420;
	font-weight: 700;
	font-size: 2rem;
	line-height: 2rem;
	text-transform: uppercase;
}

.banner_image {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	width: 100%;
	height: 100%;
	background-size: cover !important;
    transition: all 0.4s ease;
}

.banner_image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center top;
}

.banners a:hover .banner_image {
	-webkit-transform: scale(1.04);
    -moz-transform: scale(1.04);
    -ms-transform: scale(1.04);
    -o-transform: scale(1.04);
     transform: scale(1.04);
}

.banners a:hover .banner_text {
	background: rgba(55, 55, 55, 0.9);
}

/* actions */

.actions {
	float: left;
	width: 100%;
	margin-bottom: 15px;
}

.actions a {
	float: left;
	width: 49%;
	margin-right: 2%;
	margin-bottom: 25px;
	position: relative;
	overflow: hidden;
}

.actions a:nth-child(2n) {
	margin-right: 0;
}

.actions a img {
	float: left;
	width: 100%;
	max-height: 200px;
	position: relative;
	z-index: 1;
	-webkit-backface-visibility: hidden;
	transition: all 0.4s ease;
}

.actions a:hover img {
	-webkit-transform: scale(1.04);
    -moz-transform: scale(1.04);
    -ms-transform: scale(1.04);
    -o-transform: scale(1.04);
     transform: scale(1.04);
}

.actions a span:not(.text-only) {
	float: left;
	width: 100%;
	text-align: right;
	font-size: 0.8em;
	color: #888;
	background: #fff;
	padding-top: 6px;
	position: relative;
	z-index: 2;
}

.actions a div.action_banner {
	float: left;
	width: 100%;
	position: relative;
}

.actions a div.action_banner img {
	float: left;
	width: 100%;
}

.actions a div.action_banner h2 {
	position: absolute;
	top: 30%;
	left: 30px;
	z-index: 10;
	width: 50%;
	font-size: 2rem;
	line-height: 2rem;
	font-weight: 400;
	background: none;
	text-transform: none;
	text-align: left;
	transition: all 0.3s;
}

.actions a div.action_banner h2 b {
	font-weight: 700;
}

.actions a:hover div.action_banner h2 {
	left: 40px;
}

/* news */

.news {
	float: left;
	width: 100%;
	margin-bottom: 15px;
}

.news a {
	float: left;
	width: 49%;
	margin-right: 2%;
	margin-bottom: 25px;
	position: relative;
	overflow: hidden;
	text-decoration: none;
}

.news a:nth-child(2n) {
	margin-right: 0;
}

.news a strong, .actions a strong {
	float: left;
	width: 100%;
	color: #3e1a5b;
	font-size: 1.3rem;
	font-weight: 700;
	line-height: 1.5rem;
	margin-bottom: 5px;
}

.news a img {
	float: left;
	width: 100%;
	margin: 15px 0;
}

.news a div.video {
	float: left;
	width: 100%;
	margin: 15px 0;
}

.news a span, .actions a span {
	float: left;
	width: 100%;
	color: #888;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.2rem;
	margin-bottom: 15px;
}

.news a u, .actions a u {
	display: inline-block;
	color: #760098;
	background: #ffe420;
	text-decoration: none;
	text-transform: uppercase;
	font-weight: 600;
	font-size: 0.8rem;
	line-height: 0.8rem;
	padding: 10px 20px;
	border-radius: 3px;
	margin-right: 10px;
	margin-bottom: 10px;
}

.news a:hover u, .actions a u {
	color: #fff;
	background: #760098;
}

/* home brand logo's */

.home_brand_logo {
	float: left;
	width: 16.66%;
	padding: 15px 1%;
	box-sizing: border-box;
}

.home_brand_logo .image {
	float: left;
	width: 100%;
	position: relative;
	margin-bottom: 20px;
}

.home_brand_logo .image div {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	width: 100%;
	height: 100%;
	background-size: contain !important;
}

.home_brand_logo .image img {
	float: left;
	width: 100%;
	opacity: 0;
}

/* related - based on spotlights */

.related-articles {
	margin-bottom: 25px;
}

.related-article {
	display: flex;
	flex-direction: row;
	justify-content: center;
	border: 1px solid #fff;
	margin-bottom: 0.5em;
}
.related-article:hover {
	border: 1px solid #ddd;
}

.related-article a {
	text-decoration: none;
}

.related-article .image {
	flex: 0 0 20%;
	max-width: 60px;
	position: relative;
}

.related-article .image img {
	width: 100%;
}

.related-article .image img {
	opacity: 0;
}

.related-article .image img.active {
	opacity: 1;
}

.related-article .image div {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	width: 100%;
	height: 100%;
	background-size: contain !important;
}

.related-article .info {
	flex: 1 1 auto;
	padding: 0.5em 1em;
}

.related-article .price, .related-article a.addtocart {
	flex: 0 0 auto;
	display: flex;
	justify-content: center;
}

.related-article .price span {
	display: inline-block;
	color: #ffe420;
	background: #5c007d;
	font-size: 0.8rem;
	line-height: 1rem;
	height: 1rem;
	font-weight: 600;
	padding: 5px 8px;
	padding-left: 4px;
	position: relative;
	margin: auto 0.25em;
}

.related-article .price span:before {
	content: "";
	position: absolute;
	top: 0;
	left: -10px;
	width: 10px;
	height: 100%;
	background: url('../_graphics/bg_price.png') center left no-repeat;
	background-size: 50px auto;
}

.related-article a.addtocart {
	display: inline-block;
	color: #760098;
	background: #ffe420;
	text-decoration: none;
	text-transform: uppercase;
	font-weight: 600;
	line-height: 1rem;
	height: 1rem;
	padding: 10px 26px;
	border-radius: 3px;
	position: relative;
	margin: auto 0;
}

.related-article a.addtocart:after {
	content: "\f217";
	font-family: "FontAwesome";
	font-size: 18px;
	font-weight: 400;
	color: #760098;
	left: 15px;
	top: 10px;
	position: absolute;
}

.related-article a.addtocart:hover {
	color: #fff;
	background: #760098;
}

.related-article a.addtocart:hover:after {
	color: #fff;
}

/* spotlights */

@import "spotlights";

/* categories */

.categories {
	float: left;
	width: 100%;
	margin-bottom: 25px;
	display: flex;
	flex-wrap: wrap;
}

a.category {
	float: left;
	width: 20%;
	margin-bottom: 15px;
	padding: 15px 1%;
	text-decoration: none;
	box-sizing: border-box;
	border: 1px solid #fff;
}

a.category strong {
	float: left;
	width: 100%;
	text-align: center;
	font-size: 1rem;
	font-weight: 600;
	color: #888;
}

a.category div.image {
	float: left;
	width: 100%;
	position: relative;
	margin-bottom: 20px;
}

a.category div.image img {
	float: left;
	width: 100%;
	opacity: 0;
}

a.category div.image img.active {
	opacity: 1;
}

a.category div.image div {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	width: 100%;
	height: 100%;
	background-size: contain !important;
}

a.category:hover {
	border: 1px solid #ddd;
}

/* sticky phone button */

.sticky_phone_button {
	position: fixed;
	bottom: -80px;
	right: 50px;
	overflow: hidden;
	z-index: 1000;
	color: #5c007d;
	background: #ffe420;
	padding: 15px 20px 10px 20px;
	text-decoration: none;
	transition: all 0.3s;
}

.sticky_phone_button img {
	float: left;
	width: 48px;
	margin-right: 15px;
}

.sticky_phone_button div {
	float: right;
}

.sticky_phone_button span {
	float: left;
	width: auto;
	font-size: 0.8em;
	font-weight: 600;
}

.sticky_phone_button b {
	float: left;
	width: auto;
	margin-top: -6px;
	font-size: 1.6em;
	font-weight: 800;
	white-space: nowrap;
}

.sticky_phone_button:hover {
	background: #eace26;
}

.sticky_phone_button.active {
	bottom: 0;
}

/* filters toggle */

.content a.filters_toggle {
	float: left;
	width: 100%;
	color: #fff;
	background: #3e1a5b;
	border: 0 !important;
	font-size: 1.1rem;
	text-align: center;
	text-decoration: none;
	padding: 12px 0;
	margin-bottom: 15px;
	border-radius: 3px;
	display: none;
}

/* filters */

.filters {
	float: left;
	width: 20%;
	box-sizing: border-box;
	padding-right: 25px;
	border-right: 4px solid #ffe420;
	margin-bottom: 25px;
}

.filter_block {
	float: left;
	width: 100%;
	margin-bottom: 0;
	overflow: hidden;	
}

.filter_block.hidden {
	display: none;
}

.filter_block span {
	float: left;
	width: 100%;
	cursor: pointer;
	color: #3e1a5b;
	font-size: 1em;
	line-height: 1em;
	font-weight: 700;
	margin-bottom: 10px;
	transition: color 0.3s;
	position: relative;
}

.filter_block span.active b {
	color: #760098;
}

.filter_block span i.fa {
	position: absolute;
	top: 4px;
	right: 0;
	z-index: 100;
	font-size: 12px;
	font-weight: 400;
	transition: opacity 0.3s;
}

.filter_block span.active i.fa-plus {
	opacity: 0;
}

.filter_block span.active i.fa-minus {
	opacity: 1;
}

.filter_block label {
	float: left;
	width: 100%;
	cursor: pointer;
}

.filter_list_wrapper {
	float: left;
	width: 100%;
	margin-top: 5px;
  	margin-bottom: 15px;
  	position: relative;
  	display: none;
}

.filter_list_wrapper.active {
	display: block;
}

.filter_list_wrapper.brand {
	margin-top: 0;
}

.filter_list_wrapper.brand:after {
	position: absolute;
	top: 10px;
	right: 10px;
	z-index: 100;
    content: "\f002";
    font-family: "FontAwesome";
    font-size: 13px;
    color: #888;
    pointer-events: none;
    transition: all 0.3s;	
}

.filter_block ul {
	float: left;
	width: 100%;
	list-style-type: none;
	margin-bottom: 0;
}

.filter_block ul li {
	float: left;
	width: 100%;
	font-size: 0.9rem;
	line-height: 1rem;
	margin-left: 0;
	margin-bottom: 7px;
	color: #888;
}

.filter_block ul li input.checkbox {
	float: left;
	width: 15px;
	height: 15px;
	margin-right: 6px;
	margin-left: 2px;
}

.filter_block ul li b {
	float: right;
	width: calc(100% - 23px);
	font-weight: 400;
}

.filter_block ul li:hover {
	color: #4d4d4d;
}

.filter_block ul li.active {
	color: #4d4d4d;
}

/* nanoscroller */

.nano {
	float: left;
  	position: relative;
  	width: 99%;
  	height: 85px;
  	overflow: hidden;
}

.nano > .nano-content {
	position: absolute;
	overflow: scroll;
	overflow-x: hidden;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.nano > .nano-content:focus {
	outline: thin dotted;
}

.nano > .nano-content::-webkit-scrollbar {
	display: none;
}

.has-scrollbar > .nano-content::-webkit-scrollbar {
	display: block;
}

.nano > .nano-pane {
	background-image: url('../_graphics/bg_nanoslider_pane.png');
    background-color: transparent;
    background-position: 50% 0;
	position: absolute;
	width: 5px;
	right: 0;
	top: 0;
	bottom: 0;
	/*visibility : hidden\9;*/
	opacity: .99;
	transition: .2s;
}

.nano > .nano-pane > .nano-slider {
	background: #ccc;
	position: relative;
	margin: 0;
	cursor: pointer;
}

.nano:hover > .nano-pane, .nano-pane.active, .nano-pane.flashed {
	visibility: visible\9;
	opacity: 0.99;
}

/* brand livesearch */

.livesearch_brand {
	float: left;
	width: 94%;
	border: 0;
	font-size: 0.9rem;
	color: #888;
	background: #eee;
	padding: 8px 3%;
	margin-bottom: 10px;
	outline: none;
}

.livesearch_wrapper {
	float: left;
	width: 100%;
	margin-top: 5px;
	position: relative;
}

/* category info */

.category-info {

	.button.related-category {
		background-color: white;
		border-color: yellow;
		border-style: solid;
		border-width: 5px;
		color: purple;

		&:hover{
			background-color: unset;
		}
	}
}

/* products */

.products {
	float: right;
	width: 77%;
}
.no-filter {
	margin: auto;
	width: 100%;
}

/* crumb */

.faq .crumb {
	float: unset;
	width: unset;
}

.crumb {
	float: left;
	width: 45%;
	color: #4d4d4d;
	font-size: 0.75rem;
	margin-bottom: 15px;
}

.crumb a {
	color: #888;
}

.crumb a:hover {
	color: #4d4d4d;
}

.crumb i {
	color: #aaa;
	display: inline-block;
	font-style: normal;
	margin: 0 3px;
}

/* paging */

.paging {
	float: right;
	width: 55%;
	text-align: right;
	font-size: 0.9rem;
	line-height: 1rem;
	margin-bottom: 15px;
}

.paging a {
	color: #888;
}

.paging em {
	display: inline-block;
	margin: 0 1px;
	font-size: 0.8rem;
	color: #760098;
	font-style: normal;
}

.paging a:hover {
	color: #3e1a5b;
}

.paging a.active {
	font-weight: 600;
	color: #760098;
	text-decoration: none;
}

.paging a.next {
	font-weight: 600;
	color: #760098;
	text-decoration: none;
}

.paging a.next:hover {
	color: #3e1a5b;
}

/* counter */

.product_counter {
	float: left;
	width: 60%;
	color: #888;
	font-size: 0.8rem;
	margin-bottom: 15px;
}

/* filter info */

.filter_info {
	float: left;
	width: 100%;
	margin-bottom: 20px;
}

a.delete_filter {
	float: left;
	font-size: 0.75rem;
	font-weight: 400;
	padding: 5px 9px;
	color: #eee;
	background: #5c007d;
	border: 1px solid #5c007d !important;
	border-radius: 3px;
	margin-right: 8px;
	margin-bottom: 8px;
	text-decoration: none;
}

a.delete_filter:hover {
	color: #fff;
	background: #760098;
	border: 1px solid #760098 !important;
}

a.delete_filter i {
	display: inline-block;
	vertical-align: top;
	font-size: 13px;
	margin-top: 2px;
	margin-left: 3px;
	color: #eee;
	transition: color 0.3s;
}

a.delete_filter:hover i {
	color: #fff;
}

a.reset_filter {
	float: left;
	text-decoration: none;
	font-size: 0.75rem;
	font-weight: 400;
	padding: 5px 9px;
	margin-right: 8px;
	margin-bottom: 8px;
	color: #888;
	border: 1px solid #bbb !important;
	border-radius: 3px;
	transition: border 0.3s;
}

a.reset_filter:hover {
	color: #4d4d4d;
	border: 1px solid #4d4d4d !important;
}

a.reset_filter i {
	display: inline-block;
	vertical-align: top;
	font-size: 13px;
	margin-top: 2px;
	margin-left: 3px;
	color: #888;
	transition: color 0.3s;
}

a.reset_filter:hover i {
	color: #4d4d4d;
}

/* product list */

@import "product-list";

@import "product-banners";

/* energy labels */
@import "energy-label";

/* product usp's */

span.usp {
	float: left;
	width: 100%;
	margin-bottom: 7px;
	font-size: 0.9rem;
	line-height: 0.9rem;
}

span.usp.green {
	color: #9bc53d;
}

span.usp.orange {
	color: #f5a623;
}

span.usp.red {
	color: #ff3c15;
}

span.usp.blue {
	color: #4a90e2;
}

span.usp i {
	margin-right: 2px;
	width: 1.3rem;
}

/* product price */

a.product div.price, div.product div.price {
	position: relative;
	float: left;
	display: flex;
	align-items: center;
	gap: 5px;
	width: 100%;
	margin-top: 15px;
}

a.product div.price strike, div.product div.price strike {
	display: inline-block;
	color: #888;
	font-size: 1rem;
	font-weight: 400;
	margin-bottom: 5px;
}

a.product div.price b, div.product div.price b {
	display: inline-block;
	width: auto;
	color: #ffe420;
	background: #5c007d;
	font-size: 1rem;
	line-height: 1rem;
	font-weight: 600;
	padding: 7px 8px;
	padding-left: 4px;
	margin-bottom: 0;
	position: relative;
}

a.product:hover div.price b, div.product:hover div.price b {
	background: #760098;
}

a.product div.price b i, div.product div.price b i {
	padding-left: 8px;
	font-size: 18px;
	margin-left: 5px;
	border-left: 1px solid #eee;
}

a.product div.price b:before, div.product div.price b:before {
    content: "";
    position: absolute;
    top: 0;
    left: -14px;
	width: 14px;
	height: 100%;
	background: url('../_graphics/bg_price.png') center left no-repeat;
	background-size: 50px auto; 
}

a.product:hover div.price b:before, div.product:hover div.price b:before {
	background: url('../_graphics/bg_price_hover.png') center left no-repeat;
	background-size: 50px auto; 
}

a.product .price__compare, div.product .price__compare {
	position: relative;
	float: left;
	z-index: 99;
	display: inline-block;
	cursor: pointer;
	margin-left: 12px;
	padding-top: 8px;
	color: #888;
	font-size: 0.9rem;
	line-height: 1rem;
	input.checkbox {
		vertical-align: middle;
		width: 15px;
		height: 15px;
		margin-right: 6px;
		margin-bottom: 3px;
	}
	&:hover {
		color: #4d4d4d;
	}
}

a.product span.price_info, div.product span.price_info {
	font-size: .8rem;
	font-weight: bold;
	line-height: 0.8rem;
	color: black;
	margin-top: 8px;
}

/* usp blocks */

.usp_blocks {
	float: left;
	width: 100%;
	margin-top: 10px;
	margin-bottom: 50px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.usp_blocks span {
	float: left;
	width: 24%;
	color: #3e1a5b;
	background: #ffe420;
	font-size: 1.1rem;
	font-weight: 600;
	line-height: 1.4rem;
	padding: 20px;
	padding-bottom: 30px;
	box-sizing: border-box;
	position: relative;
}

.usp_blocks span hr {
	position: absolute;
	left: 7.5%;
	width: 85%;
	bottom: 15px;
	border-bottom: 1px solid #e1b91e;
	margin-bottom: 0;
}

/* product page */

@import "product-page";



#usps {
	width: 100%;
	margin-bottom: 20px;
    list-style-type: none;
}

#usps li{
	color: #4d4d4d;
	font-size: 0.9rem;
	line-height: 1.1rem;
    position: relative;
    padding: 6px 0 0 20px;
    margin-left: 0;
	margin-bottom: 0;
}

#usps li:after {
    content: "\f058";
    font-family: "FontAwesome";
    font-size: 15px;
    color: #4d4d4d;
    left: 0;
    top: 6px;
    position: absolute;
    pointer-events: none;
}

#usps li.additional-service {
	display: flex;
	border: 3px solid #eee;
	align-items: center;
	padding: 0.4rem 0.4em 0.4rem 2.5em;
	margin-bottom: 0.5rem;
}

#usps li.additional-service:after {
    content: "";
}

#usps li.additional-service input.additional-service-checkbox {
    left: 1em;
    position: absolute;
}

#usps li.additional-service {

	label {
		font-size: 0.8rem;
	}

	span.price {
		font-size: unset;
		font-weight: bold;
		flex-shrink: 0;
		color: $purple-middle;
	}
}


/* product contact links */

a.contact {
	float: left;
	width: 100%;
	font-size: 0.9rem;
	line-height: 1.1rem;
	color: #888;
	margin-bottom: 8px;
}

a.contact i {
	font-size: 1.1rem;
	margin-right: 5px;
}

/* product text */

.product_text_left {
	float: left;
	width: 40%;
}

.product_text_right {
	float: right;
	width: 58%;
}

table.specs {
	/*float: left;*/
	width: 100%;
}

table.specs td {
	padding: 6px 0;
	font-size: 0.9rem;
	line-height: 0.9rem;
	border-bottom: 0;
}

table.specs td.label {
	color: #888;
	border-bottom: 0;
	border-right: 1px solid #ddd;
}

table.specs td.value {
	color: #4d4d4d;
	padding-left: 15px;
}

table td.red, table.specs td.value.red {
	color: #ff3c15;
}

table td.green, table.specs td.value.green {
	color: #9bc53d;
}

/* cart steps */

@import "cart-steps";

/* cart */

@import "cart";


/* form */

.form {
	float: left;
	width: 75%;
	margin-bottom: 25px;
}

/* form labels */

.form span.label {
	float: left;
	width: 100%;
	color: #760098;
	margin-bottom: 5px;
}

.form span.label.error {
	color: #ff3c15;
	margin-bottom: 3px;
	a {
		margin-left: 5px;
	}
}

.form span.hint {
	float: left;
	width: 100%;
	font-weight: normal;
	font-size: 0.7rem;
	color: #aaa;
	margin-top: -5px;
	margin-bottom: 10px;
}

.form span.hint a {
	color: #aaa;
}

.form span.hint a:hover {
	color: #4d4d4d;
}

.form span.label b {
	font-size: 0.75rem;
	font-weight: 400;
	color: #aaa;
}

/* form fields */

.form input.text {
	float: left;
	width: 95%;
	background: #fff;
	border: 1px solid #ddd;
	padding: 10px;
	margin-top: 3px;
	margin-bottom: 10px;
	color: #4d4d4d;
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
	font-size: 1rem;
}

.form input.text.error {
	border: 1px solid #ff3c15;
}

.form input.text.small {
	width: 30%;
	min-width: 100px;	
}

.form textarea {
	float: left;
	width: 95%;
	height: 100px;
	background: #fff;
	border: 1px solid #ddd;
	padding: 10px;
	margin-top: 3px;
	margin-bottom: 10px;
	color: #4d4d4d;
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
	font-size: 1rem;
}

.form textarea.error {
	border: 1px solid #ff3c15;
}

.inline-form {
	display: flex;
	flex-wrap: wrap;
	gap: 1em;

	input, input.text, select, button {
		width: auto;
	}
}

.select_wrapper {
	float: left;
	width: 50%;
	position: relative;
	margin-top: 3px;
	margin-bottom: 15px;
}

.select_wrapper.small {
	width: 20%;
	margin-right: 15px;
}

.select_wrapper.large {
	width: 98%;
}

.select_wrapper:after {
    content: "\f078";
    font-family: "FontAwesome";
    font-size: 13px;
    color: #fff;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 12px 12px 0;
    position: absolute;
    pointer-events: none;
    line-height: 14px;
    background-color: #760098;
}

select {
	box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-shadow: none;
    display: inline-block;
    zoom: 1;
    color: #4d4d4d;
    background-color: #fff;
    padding: 8px 40px 8px 1%;
    margin: 0 7px 0 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    font-family: 'Open Sans', sans-serif;
	font-weight: 400;
	font-size: 1rem;
    border: 1px solid #ddd;
    width: 100%;
    cursor: pointer;
    outline: none;
    transition: border 0.3s;
}

select::-ms-expand {
    display: none;
}

.form select.error {
	border: 1px solid #ff3c15;
}

.form label {
	cursor: pointer;
}

.form input.checkbox {
	float: left;
	vertical-align: top;
	display: block;
	overflow: hidden;
	padding: 0; 
	border: 0;
	margin-left: 2px;
	margin-top: 2px;
	margin-right: 6px;
	width: 15px;
	height: 15px;
	line-height: 1em;
}

/* feedback */

div.feedback {
	float: left;
	width: 100%;
	padding: 15px 0;
	font-family: 'Open Sans', sans-serif;
	font-weight: 600;
	font-size: 1.1rem;
	text-align: center;
	color: #fff;
	background: #9bc53d;
	margin-bottom: 25px;
}

div.feedback a {
	color: white;
}

div.feedback.error {
	background: #e4572e;
}

div.feedback.special {
	color: #760098;
	background: #ffe420;
}

a.feedback, .highlight {
	padding: 15px 0;
	font-family: 'Open Sans', sans-serif;
	font-weight: 600;
	font-size: 1.1rem;
	text-align: center;
	text-decoration: none;
	color: #760098;
	background: #ffe420;
	margin-bottom: 25px;
	display: block;
	clear: both;
}

a.feedback:hover, .highlight:hover {
	color: #fff;
	background: #760098;
}

/* button */

button::-moz-focus-inner { 
    border: 0;
    padding: 0;
}

button {
	display: inline-block;
	cursor: pointer;
	overflow: visible;
	outline: none;
	color: #fff;
	background: #5c007d;
	text-decoration: none;
	text-transform: uppercase;
	font-family: 'Open Sans', sans-serif;
	font-weight: 600;
	font-size: 0.9rem;
	line-height: 0.9rem;
	padding: 10px 20px;
	border-radius: 3px;
	margin-right: 10px;
	margin-bottom: 10px;
	transition: all 0.3s;
}

button:hover {
	background: #760087;
}

button:disabled {
	color: #cccccc;
	background-color: #999999;
	cursor: no-drop;
}

/* footer */

.footer {
	float: left;
	width: 100%;
	padding: 35px 0;
	font-size: 0.9em;
	font-weight: 400;
	line-height: 1.1em;
	color: #4d4d4d;
	background: #eee;
}

.footer_block {
	float: left;
	width: 22%;
	margin-right: 3%;
	line-height: 1.6em;
}

.footer_block a {
	color: #888;
}

.footer_block a.parent {
	float: left;
	width: 100%;
	font-weight: 800;
	font-size: 1.2rem;
	text-decoration: none;
	color: #3e1a5b;
	margin-bottom: 15px;
}

.footer_block a:hover {
	color: #760098;
}

.footer_block a.parent:hover {
	text-decoration: underline;
}

.footer a.phone {
	font-size: 1.1rem;
	line-height: 0.8rem;
	font-weight: 600;
	color: #5c007d;
	text-decoration: none;
}

.footer a.phone:hover {
	color: #760098;
	text-decoration: underline;
}

.footer span.small {
	color: #888;
	font-size: 0.8rem;
}

.footer a.button {
	display: inline-block;
	color: #fff;
	background: #5c007d;
	font-size: 0.9rem;
	line-height: 0.9rem;
	border-radius: 3px;
	margin-top: 5px;
	margin-bottom: 20px;
	padding: 10px 20px;
	white-space: nowrap;
	text-transform: uppercase;
	text-decoration: none;
	position: relative;
}

.footer a.button:hover {
	background: #760087;
}

.footer a.button.icon_contact {
	position: relative;
	padding-left: 40px;	
}

.footer a.button.icon_contact:after {
 	content: "\f003";
    font-family: "FontAwesome";
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    left: 15px;
    top: 10px;
    position: absolute;
}

.footer a.button.icon_truck {
	position: relative;
	padding-left: 40px;
}

.footer a.button.icon_truck:after {
 	content: "\f0d1";
    font-family: "FontAwesome";
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    left: 15px;
    top: 10px;
    position: absolute;
}

.footer a.button.icon_login {
	position: relative;
	padding-left: 40px;
}

.footer a.button.icon_login:after {
 	content: "\f007";
    font-family: "FontAwesome";
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    left: 15px;
    top: 10px;
    position: absolute;
}

.footer a.button.less_margin {
	margin-bottom: 2px;
}

.footer a.button.no_margin {
	margin-bottom: 0;
}

.footer img.social {
	float: left;
	width: 30px;
	margin-right: 10px;
}

.footer img.social:hover {
	opacity: 0.75;
}

/* copyrights */

.copyrights {
	float: left;
	width: 100%;
	padding: 20px 0;
	font-size: 0.9em;
	line-height: 1.5em;
	color: #888;
}

.copyrights img {
	float: left;
	height: 24px;
}

.copyrights span {
	float: right;
	text-align: right;
}

.copyrights a {
	color: #888;
}

.copyrights a:hover {
	color: #4d4d4d;
}

.newsletter {
    float: left;
    width: 100%;
    padding: 10px 0 30px 0;
    font-size: 0.9em;
    font-weight: 400;
    line-height: 1.1em;
    color: #4d4d4d;
}

.newsletter h2 {
    margin-bottom: 20px;
}

#mc_embed_signup input.email {
    height: auto !important;
    background: #fff;
    border: 1px solid #ddd !important;
    padding: 7px !important;
    border-radius: 0 !important;
    margin-bottom: 10px !important;
    color: #4d4d4d !important;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 1rem !important;
    outline: none;
}

#mc_embed_signup input.button {
    display: inline-block;
    cursor: pointer;
    overflow: visible;
    outline: none;
    color: #fff;
    background: #5c007d;
    text-decoration: none;
    text-transform: uppercase;
    font-family: 'Open Sans', sans-serif;
    font-size: 0.9rem;
    line-height: 0.9rem;
    padding: 12px 20px !important;
    border-radius: 3px;
    transition: all 0.3s;
    height: auto !important;
    letter-spacing: 0 !important;
}

#mc_embed_signup input.button:hover {
    background: #760087;
}

.dark {
	color: #4d4d4d;
}

.textarea {
	float: left;
	width: 95%;
	background: #fff;
	border: 1px solid #ddd;
	padding: 10px;
	margin-top: 3px;
	margin-bottom: 10px;
	color: #4d4d4d;
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
	font-size: 1rem;
}


a.shop-title {
	width: 33%;
}

a.product, div.product {
	padding: 15px;
}

a.product div.info strong, div.product div.info strong {
	margin-bottom: 5px;
}


ul.select-delivery-country {
	margin: 2em 0;

	li {
		margin: 1em 0;
		font-size: 2rem;

		@media all and (max-width: $phone) {
			font-size: 1.3rem;
		}

		list-style-type: none;

		a {
			display: flex;
			align-items: center;
			color: black;
			text-decoration: none;

			img {
				height: 1em;
				margin-right: .5em;
			}
		}
	}
}

.flex-basket {
	position: relative;
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	align-items: flex-start;
	padding-bottom: 100px;

	& > div {
		&.flex-basket-form {
			flex: 7 1 170px;
		}
		&.flex-basket-spacer {
			flex: 2 1 1px;
		}
		&.flex-basket-articles {
			flex: 1 1 520px;

			> div {
				position: absolute;
				max-height: 100%;
				overflow-y: auto;

				background: linear-gradient(#ffffff 33%, rgba(255, 255, 255, 0)),
				            linear-gradient(rgba(255, 255, 255, 0), #ffffff 66%) 0 100%,
				            radial-gradient(farthest-side at 50% 0, rgba(34, 34, 34, 0.5), rgba(0, 0, 0, 0)),
				            radial-gradient(farthest-side at 50% 100%, rgba(34, 34, 34, 0.5), rgba(0, 0, 0, 0)) 0 100%;
				background-color: #ffffff;
				background-repeat: no-repeat;
				background-attachment: local, local, scroll, scroll;
				background-size: 100% 45px, 100% 45px, 100% 15px, 100% 15px;
			}
		}
		&.flex-basket-submit {
			flex: 0 0 100%;
		}
	}
}

@import "jquery.modal";
@import "fresco";
@import "tipso.min";
@import "nouislider";
@import "slick";
@import "tippy";
@import "loeters-custom";
@import "media-queries";

@import "colors";

.product_list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 2em 0;
}

.product-container {
  width: 33.3%;
  position: relative;

  &:hover {
    outline: 1px solid #ddd;
  }

  .image-badges {
    position: relative;
    display: inline-block;
    top: -15px;
    left: -20px;
    z-index: 99;
  }

  .add-to-cart {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 99;
  }

  a.product-container-link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
  }
}

a.product , div.product {
  position: relative;
  display: flex;
  flex-direction: column;
  float: left;
  width: 100%;
  height: 100%;
  text-decoration: none;
  box-sizing: border-box;

  div.image {
    float: left;
    width: 100%;
    position: relative;
    margin-bottom: 25px;
  }

  div.image img {
    float: left;
    width: 100%;
    opacity: 0;
  }

  div.image img.active {
    opacity: 1;
  }

  div.image div {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-size: contain !important;
  }



  /* product info */

  div.info {
    float: left;
    width: 100%;

    strong {
      float: left;
      width: 100%;
      margin-bottom: 5px;
      color: #760098;
      font-weight: 700;
      font-size: 1rem;
      line-height: 1rem;
      text-transform: uppercase;
    }

    b {
      float: left;
      width: 100%;
      margin-bottom: 10px;
      color: #3e1a5b;
      font-weight: 600;
      font-size: 0.85rem;
      line-height: 1rem;
    }

    span.usp {
      font-size: .8rem;

      i {
        width: 1.1rem;
      }
    }

    &.price-info {
      margin-top: auto;
    }
  }

  ul.specs {
    float: left;
    width: 100%;
    border-top: 1px solid #ddd;
    padding-top: 8px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 8px;
    color: #888;
    font-size: 0.8rem;
    line-height: 0.9rem;
    margin-bottom: 15px;
  }

  ul.specs li {
    margin-left: 18px;
    padding-left: -2px;
    margin-bottom: 1px;
  }

  .stock-info--in-stock,
  .stock-info--in-stock-no-purchase {
    color: $green;
  }
}

span.pickup-reduction {
  display: inline-block;
  padding: 2px 4px;

  background: $red;
  color: white;
  font-size: 10px;
  font-weight: 600;
}

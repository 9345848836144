@import '_colors.scss';
ul.slideshow {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  li {
    cursor: pointer;
    margin-right: 1rem;
    max-width: 100% !important;
    padding: 15px;
    display: flex;
    img {
      display: block;
      margin: auto;
      height: auto;
      max-height: 400px;
      max-width: 100%;
    }
  }
}
// thumbs
// Outer-most blocks. Want to juxtapose them.
ul.lSGallery {
  margin-top: 15px !important;
  transform: translate3d(0px, 0px, 0px) !important;
  width: 100% !important;
  max-width: 1000px !important;
  display: flex;
  li {
    margin: 0 0 5px;
    // width: 10% !important;
    border-radius: 5px !important;
    padding: 5px;
    border: 1px solid #ddd;
    display: flex;
    align-items: center;
    &:hover {
      border-color: #888;
    }
    &.active {
      border: 2px solid $purple-middle;
    }
    img {
      width: 100%;
    }
  }
}
@media only screen and (max-width: 768px) {
  .lSSlideWrapper,
  .lSGallery {
    margin: 0;
    width: 98% !important;
  }
}
.lSAction > a {
  background-image: url('../_graphics/slideshow-controls.png');
}
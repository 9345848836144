@media screen and (max-width: $mq-md) {
  .sectioned-page {
    .section {
      .flex {
        flex-wrap: wrap;
      }
    }
  }
}

table.compare {
  tr.property-name {
    display: none;
    font-weight: bold;
  }
}

@media screen and (max-width: $mq-sm2) {

  .compare-wrapper {
    height: 100vh;
    width: 100vw;
    overflow: auto;
    position: relative;
    left: -4%;
  }

  table.compare {
    width: 100%;
    font-size: small;
    table-layout: fixed;
    border-collapse: collapse;

    thead {
      top: -2px;
      position: sticky;
      background-color: white;
      z-index: 100;

      &.sticky {
        box-shadow: 0 1.5rem 1rem -1rem rgba(0, 0, 0, 0.2);

        .image {
          max-height: 5vh;
          transition: max-height 1s ease-in;
        }

        .addtocart {
          display: none;
        }

        .price {
          float: unset;
          b {
            position: absolute;
            top: 0;
            right: 0;
          }
        }
      }

      td {
        position: relative;
        border: none;
        width: 51vw;
        box-sizing: border-box;
      }
    }

    td {
      line-height: unset;
      padding-left: 1em;
      padding-bottom: 2px;
      border-right: 1px solid #ddd;
    }

    td.label {
      display: none;
    }

    th {
      text-align: left;
      vertical-align: bottom;
      padding: 1.5em 5px 0 1em;
      color: black;
    }

    tr.property-name {
      display: table-row;
    }
  }
}

@import "breakpoints";

.content h1.product {
  margin-bottom: 10px;
}

.content h3.product {
  font-weight: 400;
  font-size: 1rem;
  margin-bottom: 20px;
}

.content h3.product em {
  display: inline-block;
  margin: 0 1px;
  font-size: 1rem;
  color: #e1d0f0;
  font-style: normal;
}

.article-view-section {
  display: flex;
}

/* product images */

.product_images {
  position: relative;
  width: 55%;
  box-sizing: border-box;
  padding: 0 60px;


  .slick-list {
    padding-bottom: 20px;
  }

  .slick-prev:before,
  .slick-next:before {
    font-size: 35px;
  }

  div.main_image {
    box-sizing: border-box;
    padding: 15px 0px;
    z-index: 20;

    .slick-slide:not(.slick-current) {
      visibility: hidden;
    }
  }

  div.main_image a {
    background-size: contain !important;
  }

  div.main_image a img {
    opacity: 0;
  }

  div.main_image a img.active {
    opacity: 1;
  }

  div.main_image a img.cover {
    opacity: 1;
  }

  .slick-video-wrapper {
    align-self: center;
  }

  /* brand logo */

  .image-badges {
    position: absolute;
    top: 25px;
    right: 25px;
    z-index: 99;

    img.brand_logo {
      width: 75px;
      margin-bottom: .5rem;
    }

    .energy-label-container {
      display: flex;

      .energy_tag_big {
        margin-right: 16px;
      }
    }
  }

  /* product page banners */

  strong {
    position: absolute;
    top: 20px;
    left: 35px;
    z-index: 99;
    color: #fff;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1rem;
    padding: 6px 8px;
    max-width: 50%;
  }

  strong.second {
    top: 52px;
  }

  strong:before {
    content: "";
    position: absolute;
    top: 0;
    left: -13px;
    width: 12px;
    height: 100%;
    background-size: 12px 22px !important;
  }

  strong:after {
    content: "";
    position: absolute;
    top: 0;
    right: -13px;
    width: 12px;
    height: 100%;
    background-size: 12px 22px !important;
  }

  /* purple banner */

  strong.purple {
    background: #760098;
  }

  strong.purple:before {
    background: url('../_graphics/flag_purple_left.png') center left no-repeat;
  }

  strong.purple:after {
    background: url('../_graphics/flag_purple_right.png') center right no-repeat;
  }

  /* orange banner */

  strong.orange {
    background: #f5a623;
  }

  strong.orange:before {
    background: url('../_graphics/flag_orange_left.png') center left no-repeat;
  }

  strong.orange:after {
    background: url('../_graphics/flag_orange_right.png') center right no-repeat;
  }

  /* blue banner */

  strong.blue {
    background: #4a90e2;
  }

  strong.blue:before {
    background: url('../_graphics/flag_blue_left.png') center left no-repeat;
  }

  strong.blue:after {
    background: url('../_graphics/flag_blue_right.png') center right no-repeat;
  }

  /* green banner */

  strong.green {
    background: #9bc53d;
  }

  strong.green:before {
    background: url('../_graphics/flag_green_left.png') center left no-repeat;
  }

  strong.green:after {
    background: url('../_graphics/flag_green_right.png') center right no-repeat;
  }

  /* red banner */

  strong.red {
    background: #ff3c15;
  }

  strong.red:before {
    background: url('../_graphics/flag_red_left.png') center left no-repeat;
  }

  strong.red:after {
    background: url('../_graphics/flag_red_right.png') center right no-repeat;
  }

  /* extra images */

  div.extra_images {
    right: -1px;
    position: relative;
    z-index: 10;
    max-height: 400px;
    overflow: hidden;

    @media all and (max-width: $tablet) {
      display: none;
    }

    .slick-current > img,
    .slick-current > img:hover {
      border: 2px solid $purple-middle;
    }
    .slick-slide {
      margin: 5px;
    }
  }

  div.extra_images a {
    float: left;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #fff;
  }

  div.extra_images a img {
    width: 85%;
    padding: 7%;
    border: 1px solid #ddd;
    border-radius: 5px;

    &:hover {
      border-color: #888;
    }
  }

  .video-thumbnail {
    position: relative;
    &:before {
      content: "";
      display: inline-block;
      position: absolute;
      top: calc((100% - 30px)/2);
      right: calc((100% - 30px)/2);
      width: 30px;
      height: 30px;
      background: url("../_graphics/play-icon.png") center center no-repeat;
      background-size: contain;
    }
  }
}

.product_info_right {
  width: 45%;

  @media all and (max-width: $tablet) {
    width: 100%;
    padding: 10px 0;
  }
}

.product_info_top {
  box-sizing: border-box;
  background: #eee;
  padding: 20px;

  @media all and (max-width: $tablet) {
    width: 100%;
  }
}

.product_info_top strike {
  display: inline-block;
  color: #888;
  font-size: 1.2rem;
  font-weight: 600;
  margin-right: 10px;
}

.list_price_small {
  float: left;
  width: 100%;
  font-size: 0.7rem;
  line-height: 0.8rem;
  color: #4D4D4D;
  margin-top: 8px;
}

.product_info_top {

  .product_info_price {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;

    span.price {
      display: inline-block;
      color: #ffe420;
      background: #5c007d;
      font-size: 1.4rem;
      font-weight: 600;
      padding: 7px 8px;
      padding-left: 4px;
      margin-left: 15px;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: -20px;
        width: 20px;
        height: 100%;
        background: url('../_graphics/bg_price.png') center left no-repeat;
      }
    }

    span.price_info {
      flex: 1;
      display: flex;
      line-height: 1.25rem;
      color: #4D4D4D;
      margin-left: 1em;
      align-self: center;
      flex-direction: column;
      white-space: nowrap;

      .price_description {
        font-size: 0.8rem;
      }
    }

    span.price_promo_info {
      flex-basis: 100%;
      font-size: 0.9rem;
      line-height: 0.9rem;
      font-weight: 600;
      /*color: #f5a623;*/
      color: #c30002;
      margin-top: 10px;
    }

    a.addtocart {
      margin-left: .5rem;
      margin-right: 0;
      width: 40px;
      height: 40px;
      padding: 0;

      &::after {
        left: 10px;
      }
    }
  }

  span.usp {
    float: left;
    width: 100%;
    margin-bottom: 9px;
    font-size: 1rem;
    line-height: 1rem;
  }

  span.usp.green {
    color: #9bc53d;
  }

  span.usp.orange {
    color: #f5a623;
  }

  span.usp.red {
    color: #ff3c15;
  }

  span.usp.blue {
    color: #4a90e2;
  }

  a.addtocart {
    display: inline-block;
    color: #760098;
    background: #ffe420;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.1rem;
    line-height: 1.1rem;
    padding: 10px 20px;
    padding-left: 40px;
    border-radius: 3px;
    position: relative;

    &.icon-only {
      line-height: unset;
    }
  }

  a.addtocart:after {
    content: "\f217";
    font-family: "FontAwesome";
    font-size: 18px;
    font-weight: 400;
    color: #760098;
    left: 15px;
    top: 10px;
    position: absolute;
  }

  a.addtocart:hover {
    color: #fff;
    background: #760098;
  }

  a.addtocart:hover:after {
    color: #fff;
  }
  a.addtooffer {
    display: inline-block;
    color: #760098;
    background: #ffe420;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 0.8rem;
    padding: 5px 10px;
    padding-left: 20px;
    border-radius: 3px;
    position: relative;
  }

  a.addtooffer:hover {
    color: #fff;
    background: #760098;
  }

  a.greyedout {
    display: inline-block;
    color: #fff;
    background: darkgray;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 0.8rem;
    padding: 5px 10px;
    padding-left: 20px;
    border-radius: 3px;
    position: relative;
  }

  a.gotooutlet {
    display: inline-block;
    color: #fff;
    background: #9a3164;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.1rem;
    line-height: 1.1rem;
    padding: 10px 20px;
    border-radius: 3px;
    position: relative;
  }

  a.gotooutlet:hover {
    background: #84172a;
  }
}

/* stock */

@import "product-page-stock";

/* product info bottom */

.product_info_bottom {
  box-sizing: border-box;
  padding: 10px 20px;
}

@import "colors";

table.cart {
  float: left;
  width: 100%;

  th {
    text-align: left;
    font-size: 0.7rem;
    text-transform: uppercase;
    color: #888;
    font-weight: 400;
    padding: 10px 10px;
    border-bottom: 1px solid #ddd;
  }

  th.right {
    text-align: right;
  }

  td {
    padding: 15px 10px;
    vertical-align: middle;
  }

  td.thumb {
    width: 110px;
  }

  td.thumb img {
    float: left;
    width: 60px;
    margin: 20px;
  }

  td.product strong {
    font-size: 1.1rem;
    line-height: 1.3rem;
    color: #760098;
  }

  td.product b {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.1rem;
    color: #888;
  }

  td.product span.usp {
    margin-top: 5px;
    margin-bottom: 0;
  }

  td.amount input.text {
    width: 40px;
    background: #fff;
    border: 1px solid #ddd;
    padding: 10px;
    font-size: 1rem;
  }

  td.amount .select_wrapper {
    width: 80px;
  }

  td.price {
    text-align: right;
    white-space: nowrap;
  }

  td.price b {
    font-weight: 600;
  }

  td.reduction {
    text-align: right;
    color: #888;
  }

  td.reduction b {
    font-weight: 600;
    color: #ff3c15;
  }

  td.total {
    color: #760098;
    font-weight: 600;
  }

  td.right {
    text-align: right;
    white-space: nowrap;
  }

  td em {
    display: none;
  }

  /* cart subtotal */

  td.subtotal {
    font-weight: 600;
    color: #4d4d4d;
    background: #eee;
  }

  td.subtotal.right {
    text-align: right;
  }

  /* cart options */

  td.options {
    border-bottom: 0;
  }

  td.options h2 {
    margin-bottom: 10px;
  }

  td input.checkbox {
    vertical-align: middle;
    width: 15px;
    height: 15px;
    margin-right: 6px;
    margin-bottom: 3px;
  }

  td input.checkbox.hidden {
    opacity: 0;
  }

  td input.radio {
    vertical-align: middle;
    width: 15px;
    height: 15px;
    margin-right: 6px;
    margin-bottom: 3px;
  }

  td label {
    cursor: pointer;
  }

  td label:hover {
    color: #4d4d4d;
  }

  td.active {
    color: #760098;
    font-weight: 600;
  }

  table.stock {
    margin-top: 5px;
    margin-left: 25px;
    margin-bottom: 15px;
    width: 90%;
    font-size: 0.9rem;
  }

  table.stock td {
    padding: 5px 0;
  }

  table.stock tr:nth-child(5) td {
    border-bottom: 0;
  }

  table.stock td.disabled label {
    color: #ddd !important;
    cursor: default;
    font-weight: normal;
  }

  /* cart total */

  td.total {
    font-size: 1.2rem;
    font-weight: 800;
    color: #760098;
    background: #eee;
  }

  td.total.right {
    text-align: right;
  }


  tr.voucher-code-row {
    td {
      padding: 10px;
    }

    a {
      font-size: 0.9rem;
    }
  }

  .product .usp {
    color: $purple-light;

    &.green,
    &.stock-info--in-stock {
      color: $green;
    }
  }
}


.sectioned-page {

  h4 {
    margin-bottom: 0.5em;
  }

  .section {
    margin: 1em 0;

    img {
      object-fit: contain;

      &.half-width {
        width: 50%;
      }

      &.fullscreen {
        width: 100%;
      }
    }

    .flex {
      display: flex;
      flex-flow: row;
      align-content: space-evenly;

      & > * {
        flex: 1 1 min-content;
      }

      & > .main {
        min-width: 62%;
      }
    }
  }

  div:empty.white-space {
    &--small {
      height: 1em;
    }

    &--medium {
      height: 2.5em;
    }

    &--large {
      height: 4em;
    }
  }
}
@media all and (max-width: 4096px) {
    a.product, div.product {
        padding: 15px;
    }

    a.product div.info strong, div.product div.info strong {
        margin-bottom: 5px;
    }
}

/* 1280px */

@media all and (max-width: 80em) {

	.wrapper {
		width: 94%;
	}

	.header .top .wrapper,
	.header .middle .wrapper {
		width: 100%;
	}

	.header .bottom .wrapper {
		width: 100%;
	}

	ul.nav li a {
		margin-left: 16px;
		margin-right: 4px;
	}

	ul.nav li div.subnav {
		border: 0;
		border-top: 1px solid #5c007d;
		border-bottom: 1px solid #5c007d;
	}

    a.shop-title {
        width: 33%;
    }

	.modal{
		max-width: 60%;
	}
}

/* 1232px */

@media all and (max-width: 77em) {

    .search_form {
        margin-right: 15px;
    }

    .product-container {
        width: 50%;
    }

    a.product, div.product {
        margin-bottom: 25px;
        border: 1px solid #fff;
        padding: 15px;
    }

    a.product div.info strong, div.product div.info strong {
        width: 100%;
    }

    .arrow-right {
        display: none;
    }
}

/* 1200px */

@media all and (max-width: 75em) {

	.header {
		height: 160px;
	}

	.header .bottom {
		height: 50px;
	}

	.header.scroll {
		height: 90px;
	}

	ul.nav li a {
		font-size: 1.15em;
		height: 20px;
		padding-top: 17px;
		padding-bottom: 8px;
	}

	ul.nav li div.subnav {
		top: 50px;
	}

	.container {
		padding-top: 160px;
	}
}

/* 1120px */

@media all and (max-width: 70em) {

	.search_form input.text {
		width: 150px;
	}

	em.tagline {
		font-size: 1em;
		margin-top: 27px;
	}

	.hide1120 {
		display: none;
	}

	.modal{
		max-width: 70%;
	}

}

/* 1040px */

@media all and (max-width: 65em) {
	table.stock td.label {
		font-size: 0.8em;
	}

	ul.nav li a {
		font-size: 1.05em;
		margin-right: 0;
	}

	.header .top nav a {
		margin-right: 20px;
	}

	.header .top nav a:after {
		right: -10px;
	}

	.actions a div.action_banner h2 {
		font-size: 1.5rem;
		line-height: 1.5rem;
	}

    .centered_text {
        width: 100%;
        margin: 0;
    }

}

/* 960px */

@media all and (max-width: 60em) {
    table.cart td.label {
        font-size: 0.8em;
    }

	.header .top a.cart > span {
		display: none;
	}

	.header .top a.cart i {
		display: inline-block;
	}

	.header .top a.offer {
		position: unset;

		& > div {
			position: absolute;
			top: 50px;
			width: 100%;
			box-sizing: border-box;
		}
	}

	table.cart td {
		width: 92%;
		border: 0;
		padding: 5px 4%;
		text-align: left !important;
	}

	em.tagline {
		font-size: 0.8em;
		margin-top: 29px;
	}

	ul.nav li a {
		font-size: 1em;
		margin-right: 0;
		margin-left: 12px;
	}

	.sticky_phone_button {
		right: 40px;
		padding: 10px 15px 5px 15px;
	}

	.banner_text {
		margin-top: 150px;
		font-size: 1.2em;
		line-height: 1.5em;
	}

	.banner_text strong {
		font-size: 1.5rem;
		line-height: 1.5rem;
	}

	.copyrights {
		text-align: center;
	}

	.copyrights img {
		float: none;
		display: inline-block;
	}

	.copyrights span {
		float: left;
		width: 100%;
		text-align: center;
		margin-top: 10px;
	}

	.copyrights span a {
		white-space: nowrap;
	}

	.filters {
		width: 25%;
		padding-right: 15px;
	}

	.products {
		width: 72%;
	}

	.filter_block ul li {
		font-size: 0.8rem;
		line-height: 0.9rem;
	}

	.livesearch_brand {
		font-size: 0.8rem;
	}

	.modal{
		max-width: 75%;
	}

}

/* 880px */

@media all and (max-width: 55em) {

    a.product, div.product {
        width: 100%;
    }

    .header {
        position: relative;
        padding-top: 50px;
        height: auto !important;

        &.scroll .top {
            position: fixed;
            top: -50px;
            transition: all 100ms linear;
        }
    }

    .container {
        padding-top: 0;
    }

    .header .top {
        top: 0;
        position: absolute;
        height: auto;
    }

    .header .top nav {
        display: none;
    }

    .logo {
        float: left;
        position: relative;
        height: 50px !important;
    }

    .header .top a.cart {
        padding: 17px 15px;
        margin-left: 0;
        margin-right: 0 !important;
    }

    .header .middle {
        height: auto !important;
    }

    .header .middle .wrapper {
        width: 98%;
    }

    em.tagline {
        display: none;
    }

    .search_form {
        width: 100% !important;
        margin-top: 10px !important;
        margin-bottom: 10px;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .search_form input.text {
        float: left;
        width: 90% !important;
        padding: 10px 2% !important;
    }

    .search_form button {
        width: 6%;
    }

    .header .bottom {
        display: none;
        height: auto;
    }

    ul.nav li {
        float: left;
        width: 100%;
    }

    ul.nav li a {
        float: left;
        width: 94%;
        height: auto;
        padding: 16px 3% !important;
        margin: 0 !important;
        font-size: 1.4em;
        border-bottom: 1px solid #ddd !important;
    }

	ul.nav li div.subnav {
		float: left;
		width: 100%;
		height: auto !important;
		position: relative;
		left: 0;
		top: 0;
		padding-top: 25px !important;
	    border: 0;
		display: block !important;
	}

	ul.nav li div.subnav_wrapper {
		float: left;
		width: 100%;
		display: block;
	}

	ul.nav li div.subnav_block {
		float: left;
		display: block;
		width: 100%;
		margin-bottom: 25px;
		border-right: 0;
	}

	ul.nav li div.subnav_block img {
		width: 12%;
		margin: 0 6%;
	}

	ul.nav li div.subnav_block div {
		width: 73%;
	}

	ul.nav li div.subnav_block a {
		padding: 7px 0 !important;
	}

	ul.nav li div.subnav_block a.main {
		border: 0 !important;
		margin-bottom: 5px !important;
		font-size: 1em;
	}

	.banners a:nth-child(n) {
		width: 100%;
	}

	.footer_block {
		width: 47%;
		padding-right: 3%;
		margin-right: 0;
		margin-bottom: 30px;
		padding-top: 30px;
		border-top: 1px solid #ccc;
	}

	.footer .footer_block:nth-child(1),
	.footer .footer_block:nth-child(2) {
		padding-top: 0;
		border: 0;
	}

	.footer .footer_block:nth-child(3),
	.footer .footer_block:nth-child(4) {
		margin-bottom: 0;
	}

	.sidebar {
		width: 47%;
	}

	#google_maps_canvas.home {
		width: 47%;
	}

	img.text_banner {
		width: 47%;
	}

	.mobile_nav {
		display: block;
	}

	a.spotlight {
		width: 33.3%;
		padding: 15px 2%;
	}

	.spotlights a.spotlight:nth-child(6) {
		display: block;
	}

	.spotlights a.spotlight:nth-child(2n) {
		clear: both;
	}

	a.category {
		width: 33.3%;
		padding: 15px 2%;
	}

	.cart_steps span {
		font-size: 0.8rem;
	}

	.header .top a.cart div {
		display: none;
	}

    .product-container {
        width: 100%;
    }

    a.product, div.product {
        margin: 0;
        padding: 25px 3%;
    }

    a.product div.info strong, div.product div.info strong {
        width: 90%;
    }

    .shop-title .image,
    .shop-title span,
    .shop-title p,
    .shop-title b,
    .shop-title ul {
        display: none;
    }

    a.shop-title {
        border-top: 1px solid #760098;
    }

    a.shop-title:last-of-type {
        border-bottom: 1px solid #760098;
        margin-bottom: 50px;
    }

    a span.arrow-right {
        display: block;
        float: right;
        width: 0;
        height: 0;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-left: 8px solid #760098;
    }

    a.product div.info strong, div.product div.info strong {
        margin-bottom: 0px;
    }

	.flex-basket > div {
		&.flex-basket-form,
		&.flex-basket-spacer,
		&.flex-basket-articles,
		&.flex-basket-submit {
			flex: unset;
		}

		&.flex-basket-articles {
			> div {
				position: unset;
				overflow-y: unset;

				> table.cart {
					width: 95%;
				}
			}
		}
	}

	.article-view-section {
		flex-direction: column;

		.product_images {
			width: unset;
			padding: 0 10px;

			.main_image {
				padding: unset;
			}

			.slick-slider {
				margin: unset;
			}
		}
	}
}

/* 800px */

@media all and (max-width: 50em) {

	.actions a div.action_banner h2 {
		left: 15px !important;
		font-size: 1rem;
		line-height: 1rem;
	}

	.content {
		padding-top: 25px;
	}

	.content a.filters_toggle {
		display: block;
	}

	.filters {
		display: none;
		width: 100%;
		padding: 0;
		border-right: 0;
	}

	.filter_block ul li {
		font-size: 1rem;
		line-height: 1.1rem;
	}

	.livesearch_brand {
		font-size: 1rem;
	}

	.category-info {
		.collapsible {

			.collapsible-content {
				transition: all 0.4s;
				max-height: 10vh;
				overflow-y: hidden;
				mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
				margin-bottom: 2em;
			}

			&:before {
				content: '…meer';
				font-style: italic;
				position: relative;
				float: right;
				bottom: -5em;
			}

			&:after {
				content: 'minder…';
				font-style: italic;
				position: relative;
				float: right;
				bottom: 1em;
				display: none;
			}

			&.active{
				.collapsible-content {
					max-height: unset;
					mask-image: unset;
					margin-bottom: unset;
				}

				&:before {
					display: none;
				}

				&:after {
					display: inline;
				}
			}
		}
	}

	.products {
		width: 100%;
	}

	.paging.product {
		width: 100%;
		text-align: left;
		border-top: 1px solid #ddd;
		padding-top: 10px;
	}

	.cart_steps span {
		width: 33.3%;
	}

	.cart_steps span:nth-child(3) {
		border: 0;
	}

	.cart_steps span:nth-child(4),
	.cart_steps span:nth-child(5) {
		display: none;
	}

	.list_price_small {
		font-size: 0.9rem;
	}

	a.spotlight div.info b {
		font-size: 1rem;
	}

	a.product div.price b, div.product div.price b {
		font-size: 1.8rem;
		line-height: 1.8rem;
	}

	a.product div.price b:before, div.product div.price b:before {
		left: -20px;
		width: 20px;
		background-size: unset;
	}

	a.product:hover div.price b:before, div.product:hover div.price b:before {
		background-size: unset;
	}

	a.product span.price_info, div.product span.price_info {
		font-size: .9rem;
	}

	a.product div.info strong, div.product div.info strong {
		font-size: 1.2rem;
	}
}

@media all and (max-width: 768px) {
    table.stock td {
        font-size: 0.7rem;
        line-height: 1.4em;
    }

    table.stock td:empty {
        display: none;
    }

    table.stock td.label {
        font-size: 0.7rem;
    }
}

/* 720px */

@media all and (max-width: 45em) {

	.product_text_left {
		width: 100%;
		margin-bottom: 15px;
	}

	.product_text_right {
		width: 100%;
	}

	table.cart td.thumb {
		width: 70px;
	}

	table.cart td.thumb img {
		width: 50px;
		margin: 10px;
	}

	.modal{
		max-width: 90%;
	}

}

/* 640px */

@media all and (max-width: 40em) {

	.header .middle .wrapper {
		width: 96%;
	}

	.search_form input.text {
		width: 84% !important;
		padding: 10px 3% !important;
	}

	.search_form button {
		width: 10%;
	}

	.sticky_phone_button {
		right: 0;
		bottom: 0;
		padding: 10px 10px 5px 10px;
	}

	.sticky_phone_button img {
		display: none;
	}

	.sticky_phone_button span {
		font-size: 0.6em;
	}

	.sticky_phone_button b {
		margin-top: -10px;
		font-size: 1.4em;
	}

	div.usps {
		display: none;
	}

	.copyrights {
		margin-bottom: 65px;
	}

	.sidebar {
		width: 100%;
	}

	#google_maps_canvas.home {
		width: 100%;
	}

	img.text_banner {
		width: 100%;
	}

	.content h1 {
		font-size: 1.6rem;
		line-height: 2rem;
	}

	.content h2 {
		font-size: 1.2rem;
		line-height: 1.5rem;
		margin-bottom: 25px;
	}

	.actions a div.action_banner h2 {
		left: 25px !important;
		font-size: 1.3rem;
		line-height: 1.3rem;
	}

	.usp_blocks span {
		font-size: 1rem;
		line-height: 1.2rem;
		width: 48.5%;
		margin-bottom: 10px;
	}

	.form {
		width: 100%;
	}

	table.cart th {
		display: none;
	}

	table.cart tr {
		width: 100%;
		border-bottom: 1px solid #ddd;
		padding: 15px 0;
	}

	table.cart td {
		width: 92%;
		border: 0;
		padding: 5px 4%;
		text-align: left !important;
	}

	table.cart td:empty {
		display: none;
	}

	table.cart table.stock {
		width: 100%;
		margin-left: 0;
		font-size: 0.85rem;
	}

	table.cart table.stock tr {
		background: #fff !important;
	}

	table.cart td em {
		display: block;
		color: #888;
		font-size: 0.7rem;
		text-transform: uppercase;
		font-style: normal;
	}

	.hidemobile {
		display: none;
	}

	.modal{
		max-width: 100%;
	}

	.user-property-web-settings tr td:first-child{
		width: 100%;
		padding-right: 0;
	}


}

/* 560px */

@media all and (max-width: 35em) {
    #compare-panel {
        display: none;
    }

	.content {
		padding-top: 15px;
	}

	.hide_at_560 {
		display: none;
	}

	.actions a {
		width: 100%;
		margin-right: 0;
		margin-bottom: 15px;
	}

	.footer .footer_block {
		width: 100%;
		margin-right: 0;
		padding-top: 0;
		border-top: 0;
		padding-bottom: 25px;
		margin-bottom: 25px !important;
		border-bottom: 1px solid #ccc !important;
	}

	.footer .footer_block:nth-child(4) {
		padding-bottom: 0;
		margin-bottom: 0 !important;
		border-bottom: 0 !important;
	}

	.home_brand_logo {
		width: 33.33%;
	}

	.content h2 {
		width: 100%;
		text-align: left;
	}

	.content h2 strong {
		padding: 0 10px 0 0;
	}

	.content h2 strong:before,
	.content h2 strong:after {
		display: none;
	}

	.mobile_nav a {
		width: 100%;
		padding: 12px 0;
	}

	a.spotlight {
		width: 50%;
		padding: 15px;
	}

	a.category {
		width: 50%;
		padding: 15px;
	}

	.crumb {
		display: none;
	}

	.product_counter {
		display: none;
	}

	.paging,
	.paging.product {
		float: left;
		width: 100%;
		text-align: left;
		border-top: 0;
		padding-top: 5px;
	}

	table.usps td {
		float: left;
		width: 94%;
		padding-top: 5px;
		padding-bottom: 5px;
		padding-left: 6%;
	}

	table.usps td:after {
		top: 5px;
	}

	table.cart table.stock tr {
		padding: 0;
		border: 0;
	}

	table.cart table.stock td {
		width: 100%;
		padding: 5px 0;
		border-bottom: 1px solid #ddd;
	}

	table.cart table.stock td.stock,
	table.cart table.stock td.disabled {
		display: none;
	}

	.news a {
		width: 100%;
		margin-right: 0;
		margin-bottom: 20px;
	}


}

/* 480px */

@media all and (max-width: 30em) {

    .cart_steps span {
        font-size: 0.7rem;
        white-space: nowrap;
    }

	.product_info_top {
		.product_info_price {
			span.price_info {
				margin-left: .5em;
				font-size: small;

				.price_description {
					font-size: smaller;
				}
			}
		}
	}
}

/* 400px */

@media all and (max-width: 25em) {

	.header .top a.cart b {
		margin-left: 0;
		padding-left: 0;
		border-left: none;
	}

}

@media all and (max-width: 388px) {

	.product_info_top {
		.product_info_price {

			span.price {
				order: 1;
			}

			a.addtocart {
				order: 2;
			}

			span.price_info {
				order: 3;
				margin: unset;
			}

			span.price_promo_info {
				order: 4;
			}
		}
	}
}

@media print {
	.content * {
		-webkit-print-color-adjust: exact;
	}
}
:root {
  --primary_theme_color: #5c007d;

  --background_color_1: #f6f6f6;
  --primary_text_color: #000000;
  --secondary_text_color: white;
  --font_family_2: 'Open Sans', sans-serif;

  --success_color: #42950F;
  --error_color: #ae1d2f;
}

.account-page {
  display: flex;
  margin-bottom: 30px;
  @media all and (max-width: 60em) {
    flex-direction: column;
  }
  .table-title {
    box-sizing: border-box;
    padding: 8px;
    min-width: 500px;
    span {
      font-size: 18px;
    }
  }
  ul {
    li {
      list-style: none;
      padding: 8px;
      &:nth-child(2n) {
        background: #F9F9F9;
      }
      a {
        text-decoration: none;
        width: 100%;
        &:hover {
          text-decoration: underline;
        }
        &.active {
          font-weight: 700;
        }
        span {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
        &.inactive {
          cursor: default;
          pointer-events: none;
          text-decoration: none;
          color: grey;
        }
      }
    }
  }
  form {
    float: none;
    .checkbox {
      margin: 14px 0;
      display: inline-block;
      width: 100%;
    }
  }
  .account-nav {
    min-width: 300px;
    background: #F9F9F9;
    box-sizing: border-box;
    padding: 20px 20px 40px;
    ul {
      list-style-type: none;
      li {
        padding: 0;
        margin: 1em 0;
        &.title {
          font-size: 1.2rem;
          display: block;
          margin-bottom: 1.5em;
          .name {
            font-weight: bold;
          }
          .email, .contact-name {
            margin-top: .5em;
            font-size: 0.9rem;
          }
        }
        a {
          font-size: 1rem;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
          &.active {
            font-weight: 700;
          }
        }
      }
    }
  }
  .account-content {
    padding: 2em;
    flex: 1;
    @media all and (max-width: 30em) {
      padding: 5px 0 0 0;
    }
    h1 {
      margin-bottom: 1em;
    }
  }
  .price-on-demand {
    display: none;
  }
}
.account-nav {
  float: left;
  width: 300px;
  background: var(--background_color_1);
  box-sizing: border-box;
  padding: 40px 20px;
  ul {
    float: left;
    width: 100%;
    list-style-type: none;
    li {
      float: left;
      width: 100%;
      padding: 0;
      margin: 5px 0;
      a {
        float: left;
        width: 100%;
        text-decoration: none;
        color: var(--primary_text_color);
        &:hover {
          text-decoration: underline;
          color: var(--primary_theme_color);
        }
      }
      a.active {
        color: var(--primary_theme_color);
        font-family: var(--font_family_2);
        font-weight: normal;
      }
    }
  }
}
.account-block {
  float: right;
  width: calc(100% - 300px);
  box-sizing: border-box;
  padding: 40px;
  background: var(--background_color_1);
}
table.table {
  width: 100%;
  position: relative;
  border-spacing: 0;
  margin-bottom: 25px;
  th {
    background: black;
    color: white;
  }
  tr {
    &:nth-of-type(even) {
      td {
        background: rgba(211, 211, 211, 0.3);
      }
    }
  }
  th, td {
    min-width: 75px;
    text-align: left;
    padding: 5px 10px 5px 10px;
    vertical-align: top;
    line-height: 1.4em;
    border-bottom: 1px solid #ddd;
  }
  tr.grouping {
    td, th {
      color: #0a74a6;
      font-weight: bold;
      border-bottom: 1px dotted #aaa;
    }
  }
  .table__code {
    white-space: nowrap;
  }
  .table__actions {
    min-width: initial;
  }
  .table__flex-end {
    display: flex;
    justify-content: flex-end;
  }
}
table {
  .table__actions {
    a {
      text-decoration: none;
    }
    i {
      font-size: 1.5rem;
      color: black;
      margin-left: .5rem;
      &:hover {
        color: orange;
      }
    }
  }
}
div.feedback {
  float: left;
  width: 100%;
  padding: 15px 0;
  text-align: center;
  color: var(--secondary_text_color);
  background: var(--success_color);
  margin-bottom: 25px;
  @media all and (max-width: 75em) {
    margin-bottom: 5px;
  }
}
a.feedback {
  float: left;
  width: 100%;
  padding: 15px 0;
  font-size: 1.1rem;
  text-align: center;
  text-decoration: none;
  color: var(--secondary_text_color);
  background: var(--success_color);
  margin-bottom: 25px;
  &:hover {
    color: var(--secondary_text_color);
    background: var(--error_color);
  }
}
.web-users-block,
.sales-orders-block,
.sales-invoices-block {
  ul li a {
    text-decoration: none;
    width: 100%;
    &:hover {
      text-decoration: underline;
    }
    &.active {
      font-weight: 700;
    }
    span {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  .inactive {
    cursor: default;
    pointer-events: none;
    text-decoration: none;
    color: grey;
  }
}
.sales-orders-block,
.sales-invoices-block {
  h1 {
    margin-bottom: 20px;
  }
  .table-title {
    box-sizing: border-box;
    padding: 8px;
    min-width: 700px;
    span {
      font-size: 18px;
    }
  }
  div.feedback {
    float: none;
  }
  ul {
    margin-bottom: 10px;
    max-height: 500px;
    min-width: 700px;
    li {
      list-style: none;
      padding: 8px;
      &:nth-child(2n) {
        background: #F9F9F9;
      }
      div {
        width: 100%;
      }
    }
  }
  form {
    float: none;
    margin: 40px 0 80px;
    .checkbox {
      margin: 14px 0;
      display: inline-block;
      width: 100%;
    }
  }
}
.favorites {
  h2 {
    margin-bottom: 20px;
    margin-top: 20px;
  }
  ul {
    list-style-type: none;
  }
  li {
    margin-bottom: 10px;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }
  p {
    margin-bottom: 20px;
  }
}
.period-search {
  * {
    display: inline-block;
  }
  .period-links {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 15px;
    float: right;
  }
  .period-link {
    float: right;
    min-width: 120px;
    a {
      color: #000;
      margin-left: 5px;
    }
  }
}
.serial-number__service-orders-button {
  cursor: pointer;
}
th.table__product-price {
  text-align: center !important;
}
td.table__product-price {
  text-align: right !important;
  white-space: nowrap;
}
.customer-data-logged-in {
  .form {
    margin-top: 15px;
    width: 100%;
    float: none;
  }
  }
.label-value {
  margin-bottom: .2em;
  border-bottom: solid 1px lightgrey;
  label {
    width: 13em;
    font-weight: bold;
    display: inline-block;
  }
}
.news span img {
    display: none;
}

.slider-result input, input.search-text {
    display: inline-block;
    background: #fff;
    border: 1px solid #ddd;
    padding: 10px;
    font-size: 1rem;
}

.slider-result input {
    width: 20%;
}

label .slider {
    margin: 1em;
}

#compare-panel {
    position: fixed;
    bottom: 0;
    left: 50px;
    width: auto;
    max-width: 75%;
    z-index: 999;
    overflow: hidden;
    padding: 0 20px;
    color: #5c007d;
    background: #ffe420;
}
#compare-articles > div {
    padding: 0.5em 0;
    border-top: 1px dotted #3e1a5b;
}
#compare-articles > div:first-child {
    border-top-width: 0;
}

.compare-remove {
    color: #ff3c15;
}

table.compare td.label {
    color: #888;
    width: 1px;
    white-space: nowrap;
}

table.compare div.image {
    float: left;
    width: 100%;
    position: relative;
    margin-bottom: 25px;
}

table.compare div.image img {
    float: left;
    width: 100%;
    opacity: 0;
}

table.compare div.image div {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-size: contain !important;
}

table.compare a.info {
    float: left;
    width: 100%;
}

table.compare a.info strong {
    float: left;
    width: 100%;
    margin-bottom: 5px;
    color: #760098;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1rem;
    text-transform: uppercase;
}

table.compare a.info b {
    float: left;
    width: 100%;
    margin-bottom: 10px;
    color: #3e1a5b;
    font-weight: 600;
    font-size: 0.85rem;
    line-height: 1rem;
}

table.compare div.price {
    float: left;
    width: 100%;
    margin: 15px 0 15px 14px;
}

table.compare div.price strike {
    display: inline-block;
    color: #888;
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 5px;
}

table.compare div.price b {
    display: inline-block;
    width: auto;
    color: #ffe420;
    background: #5c007d;
    font-size: 1rem;
    line-height: 1rem;
    font-weight: 600;
    padding: 7px 8px;
    padding-left: 4px;
    margin-bottom: 0;
    position: relative;
}

table.compare div.price b i {
    padding-left: 8px;
    font-size: 18px;
    margin-left: 5px;
    border-left: 1px solid #eee;
}

table.compare div.price b:before {
    content: "";
    position: absolute;
    top: 0;
    left: -14px;
    width: 14px;
    height: 100%;
    background: url('../../../static/_graphics/bg_price.png') center left no-repeat;
    background-size: 50px auto;
}
#articles-message {
    text-decoration: none;
    position: fixed;
    top: 40%;
    left: 50%;
    z-index: 1001;
    overflow: hidden;
    padding: 15px 0px;
    color: #760098;
    background: #ffe420;
    width: 500px;
    margin-left: -250px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 1.1rem;
    text-align: center;
}

#articles-message:hover {
    color: #fff;
    background: #760098;
}

@media(max-width: 500px) {
    #articles-message {
        width: 100%;
        left: 0;
        margin-left: 0;
    }
}

#articles-message:hover a {
    color: #fff;
}

table.cart {
    border-collapse: collapse;
}

td.amount .select_wrapper {
    float: right;
}

table.specs td.label {
    padding-left: 0.25em;
    padding-right: 0.25em;
}

body.test-server img.logo {
    filter: invert();
}

img.payment-logos {
    height: 24px;
}

.nodes-payment-logos{
    min-height: 100px;
    margin-bottom: 10px;
    width: 300px;
}

.nodes-payment-logos.inline{
    display: inline-flex;
}

.nodes-payment-logos img{
    height: 25px;
}

.content div.checkout-confirm{
    line-height: 1.6em;
    margin-bottom: 25px;
}

.shops p {
    font-size: 0.8em;
    color: #760098;
    margin-bottom: 0.4em;
}

.weekday {
    color: #3e1a5b;
}

.service-info {
    font-size: 0.8em;
    color: #666;
}

.pickup-info {
    font-size: 0.9em;
    color: #9bc53d;
}

a.no-border {
    text-decoration: none;
}

.only-on-print {
    display: none;
}

.highlight:hover a {
    color: #fff;
}

.cart .product a {
    text-decoration: none;
}

table.cart span.usp.delivery-info {
    margin-left: 21px;
}

a.article-revenue-category {
    font-size: 1.2em;
    color: #760098;
    font-weight: bold;
    text-decoration: none;
}

.outlet .header {
    background-color: #a55e67;
    color: #fff;
}

.outlet .middle {
    background: transparent;
}

.outlet .header h1 {
    margin-top: 0.5em;
}

.outlet .header h1, .outlet .header p {
    margin-left: 280px;
}
.outlet .header .info {
    font-size: 0.8em;
    color: #e4e4e4;
}
.outlet .price-info {
    font-size: 0.8em;
    color: #888;
}
.outlet-price {
    color: #84172a;
}
.outlet .header strong {
    color: #efee00;
}
.outlet table.specs td.label {
    width: 25%;
}

.outlet .current {
    background: #9a3164;
    color: #fff;
}
.outlet .current a, .outlet .filter_block span.current.active b {
    color: #fff;
}
.outlet .filters a {
    text-decoration: none;
    padding: 0.2em 0;
}

ul.nav li.outlet-nav a {
    color: #84172a;
}

ul.nav li.outlet-nav a:hover {
    color: #9a3164;
}

a.addtocart {
    margin-right: 1em;
}

.content a.shops {
    display: inline-block;
    margin: 0.5em 0;
    color: #760098;
}
.content .cart a.shops {
    margin: 0 0 0 21px;
}

img.payment-shop {
    height: 24px;
}

span.smaller {
    font-size: 0.75em;
}

.modal{
    text-align: -webkit-center;
    max-width: 40%;
}

.user-property-web-settings td{
    padding-top: 5px;
    padding-bottom: 5px;
}

.user-property-web-settings .content td{
    border: none;
}

.user-property-web-settings .content td p,
.user-property-web-settings .content p{
    white-space: pre-wrap;
}

.user-property-web-settings tr td:first-child{
    width: 65%;
    padding-right: 5px;
}

.user-property-web-settings tr td.full-width{
    width: 100%;
    padding-right: 0;
}

.user-property-web-settings tr td:before {
    content: '';
    display: block;
    width: 5em;
}

.user-property-web-settings td:nth-child(2) img{
    max-width: 100%;
}

.user-property-web-settings .content{
    padding-top: 5px;
    padding-bottom: 5px;
}

.user-property-web-settings h2,
.user-property-web-settings h3{
    color: #760098;
}

.youtube-clip,
.youtube-clip iframe{
    width: 98%;
    height: 300px;
}

.multi-word-span{
    display: inline-flex;
}

.multi-word-span *{
    margin-right: 5px;
}

a.user-prop-web-settings-link{
    color: #ff0000;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 12px;
    font-style: italic;
    margin-left: 5px;
}

a.user-prop-web-settings-link:hover{
    color: #4d4d4d;
}

.modal a.close-modal{
    top: 5px;
    right: 5px;
}

.blocker{
    background-color: rgba(0,0,0,0.4);
}

.slick-prev:before,
.slick-next:before {
    color: #760098 !important;
}

.deliveryDateSelection{
    margin: 1em 2em;
    color: black;
    overflow: no-display;
    min-width: 40em;
    font-size: 10pt;
    width: 0;
}

.datePickerSlickSlide{
    text-align: center;
    padding: 1ex 0.5em;
    margin: 2px 0.5ex;
    border: 1px solid #ddd;
    border-radius: 3px;
    overflow: visible;
    box-sizing: border-box;
}

.datePickerSlickSlide:hover {
    margin: 0 0.5ex;
    border: #760098 2px solid;
}

input[name="deliveryDate"]:checked + .datePickerSlickSlide{
    border: #760098 2px solid;
    background-color: #fbedff;
}

input[name="deliveryDate"]:disabled + .datePickerSlickSlide{
    border: #dddddd 1px solid !important;
    color: #dddddd;
}

input[name="deliveryDate"]{
    display: none;
    max-width: 0;
}









.cart_steps {
  float: left;
  width: 100%;
  color: #888;
  background: #eee;
  margin-bottom: 20px;
}

.cart_steps b {
  font-weight: 400;
}

.cart_steps i {
  color: #760098;
}

.cart_steps span {
  float: left;
  width: 20%;
  box-sizing: border-box;
  padding: 10px;
  border-right: 1px solid #ddd;
}

.cart_steps span:nth-child(5) {
  border: 0;
}

.cart_steps span.done {
  color: #760098;
}

.cart_steps span.active {
  color: #760098;
  font-weight: 700;
}

.cart_steps span.active b {
  font-weight: 700;
}
